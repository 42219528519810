@import '../../../src/styles/themes/color.module.scss';

.container-main {
    // border: 2px solid gold;
    // height: 100vh;
    // margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
}

.sub-container {
    position: relative;
    border-radius: 5px;
    border: 1px solid #bfbdbf;
    background: #fff;
    width: 1224px;

    padding-top: 26px;
    padding-left: 25px;
    padding-right: 30px;
    margin-top: 50px;
    margin-bottom: 43px;
    padding-bottom: 21px;
    // margin: 0 auto;;
}

.header-name {
    // display: flex;
    // width: 20%;
    // justify-content: center;
    // align-items: center;
    margin-bottom: 18px;
}

.text-style {
    color: #303e4e;
    text-align: center;
    font-family: Rubik;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 18px;
}

.header-left {
    border: 2px solid darkorange;
}
.container-two-2 {
    width: 68%;
    // margin-left: 19px !important;
    margin-top: 2px;
    padding-right: 20px;
    // height: 562px;
}

.container-two-1 {
    width: 30%;
    // border: 1px solid #bfbdbf;
}

.container-two {
    display: flex;
    gap: 19px;
    height: 562px;
}

.health-record-circular-progress-container {
    width: 100%;
    height: 500px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.absolute-card {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1.5rem;
    box-sizing: border-box;
    background: $primaryWhite;
}
