@import '../../styles/themes/color.module.scss';

.loaderWrapper {
    width: 100%;
    // height: 100%;
    z-index: 99999999;
    position: absolute;
}

.loaderWrapper .loader {
    width: 100px;
    height: 100px;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background: white;
    border: 'none';
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.loader small {
    display: block;
    margin-top: 5px;
}

.linear-loader {
    .MuiLinearProgress {
        &-colorPrimary {
            background-color: $primaryBlue;
        }

        &-barColorPrimary {
            background-color: $primaryDarkBlue;
        }
    }
}

.MuiCircularProgress-colorPrimary {
    color: $primaryDarkBlue !important;
}

.loadding-pdf {
    width: 100%;
    z-index: 99999999;
    position: absolute;
}

.loadding-pdf .loader {
    width: 100px;
    height: 100px;
    text-align: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border: 'none';
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}
