@import './styles/themes/lato.scss';
@import './styles/themes/color.module.scss';
@import './styles/themes/sizing.scss';
@import './styles/themes/utilities.scss';
body {
    margin: 0;
    padding: 0;
    font-family: 'Rubik', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
code {
    font-family: 'Rubik', sans-serif;
}

.swal2-styled {
    height: 40px;
    min-width: 150px;
    font-weight: 500;
    color: $primaryWhite !important;
    background-color: $primaryDarkBlue !important;
    text-transform: capitalize !important;
}
// #st-br::-webkit-scrollbar {
//     width: 0px;
// }
// #st-br::-webkit-scrollbar-track {
//     background: rgb(179, 177, 177);
//     border-radius: 10px;
// }
// #st-br::-webkit-scrollbar-thumb {
//     background: rgb(136, 136, 136);
//     border-radius: 10px;
// }
// #st-br::-webkit-scrollbar-thumb:hover {
//     background: rgb(100, 100, 100);
//     border-radius: 10px;
// }
// #st-br::-webkit-scrollbar-thumb:active {
//     background: rgb(68, 68, 68);
//     border-radius: 10px;
// }
.form-disabled {
    pointer-events: none;
    opacity: 0.7;
}

.content-align-center {
    text-align: center;
    justify-content: center;
}
.content-align-left {
    text-align: left;
}

.appt-table .MuiGrid-root.content-align-center.MuiGrid-container {
    justify-content: flex-start;
}
/*** Generic Styles ***/

.app-layout-container-component-area {
    padding: 20px;
    h2.heading {
        font-size: 30px;
        line-height: 45px;
        margin-bottom: 30px;
        color: $primaryDarkBlue;
        letter-spacing: 0;
    }
    .component-wrapper {
        padding: 30px;
        background-color: $primaryWhite;
        border-radius: 4px;
        box-shadow: 0 0 13px 0 rgb(222 231 241 / 50%);
    }
}

// button.MuiButton-contained.top0 {
//     width: 100%;
// }

/** Select Styles **/

svg.MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconOutlined {
    color: $primaryGray;
    // background: $primaryWhite;
}
.MuiList-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.MuiPaper-root .MuiSelect-select:focus {
    background: transparent;
}
.MuiPaper-root .MuiListItem-root.Mui-selected,
.MuiPaper-root .MuiListItem-root.Mui-selected:hover {
    background-color: $lightestGray;
}
.MuiPaper-rounded {
    border-radius: 4px;
}
.MuiListItem-button:hover {
    background-color: $lightestGray !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    .MuiIconButton-root {
        color: $primaryDarkGray;
    }
}
.MuiFormControl-root.MuiTextField-root.Search-bar.MuiFormControl-fullWidth .MuiInputBase-input {
    font-size: 14px !important;
}
.MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded input {
    font-size: 14px;
}
/*** Typography Styles ***/
.MuiTypography-root {
    &.MuiTypography-colorPrimary {
        transition: color 0.2s ease-in-out 0s;
        color: $primaryBlue;
        &:hover {
            color: $secondaryBlue;
        }
    }
    p.MuiTypography-root.MuiTypography-body2 {
        &.MuiTypography-colorPrimary {
            transition: color 0.2s ease-in-out 0s;
            color: $primaryBlue;
            &:hover {
                color: $secondaryBlue;
            }
        }
    }
}
.abs-icon {
    position: absolute;
    right: 10px;
    top: 10px;
}
/** Fix for table double border **/
.MuiDataGrid-table-card-wrapper {
    .MuiDataGrid-root {
        border: none;
    }
    .MuiDataGrid-root .MuiDataGrid-columnHeader,
    .MuiDataGrid-root .MuiDataGrid-cell {
        border-right: none;
    }
}
/** Fix for Placeholder - search req.by **/
.MuiFormControl-root.MuiTextField-root.Search-bar.MuiFormControl-fullWidth .MuiInputBase-input {
    font-size: 14px;
}
.break-textspace {
    white-space: break-spaces;
}
.cursor-pointer {
    cursor: pointer;
}
.bold {
    font-weight: 700;
}
.MuiOutlinedInput-root {
    border-radius: 6px !important;
    height: fit-content;
    background: #fbfcfc !important;
}

.MuiOutlinedInput-notchedOutline {
    border: 1px solid #e9ecf1 !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid $primaryDarkGray !important;
}
.MuiInputBase-input.MuiAutocomplete-input {
    color: $primaryDarkGray !important;
    font-size: 14px !important;
}
.MuiInputBase-root:focus-within {
    box-shadow: none !important;
}
.MuiInputBase-root:focus {
    box-shadow: none !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
    border-color: #889db6 !important;
}
fieldset {
    border-color: #889db6 !important;
    border-width: 1px !important;
}

.bg-white {
    background: #fff;
}
.MuiDataGrid-columnHeaderTitleContainerContent {
    width: 100%;
}

@media only screen and (max-width: 1440px) {
    .MuiCardHeader-content .MuiCardHeader-title {
        font-size: 16px;
        line-height: 24px;
    }
    .MuiDataGrid-root {
        .MuiDataGrid-columnHeaderTitle p {
            font-size: 14px;
        }
        .MuiDataGrid-columnHeaderTitleContainer p {
            font-size: 14px;
        }
    }
    .MuiCardContent-root {
        .MuiDataGrid-columnHeader,
        .MuiDataGrid-cell {
            padding: 0 4px !important;
        }
    }
    .MuiCardHeader-action p {
        font-size: 14px;
    }
    .MuiFormControl-root.MuiTextField-root.Search-bar.MuiFormControl-fullWidth .MuiInputBase-input {
        font-size: 14px !important;
    }
    .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded input {
        font-size: 14px;
    }
}

@media only screen and (max-width: 768px) {
    .app-layout-container-component-area {
        h2.heading {
            font-size: 18px;
            line-height: 24px;
        }
    }
}

::-webkit-scrollbar {
    width: 4px;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
    display: none;
}

.card-raw {
    background: $primaryWhite;
    padding: 1.2rem !important;
    border-radius: 0.5rem;
}

.wizard_btn_holder {
    margin: 20px 0px 5px 50px;
}
.outer_stepper_holder {
    .middle_portion {       
        .booking_boxes {
            .heading_holder {
                margin-bottom: 40px;
            }
        }
    }
}
.required {
    display: contents;
    color: #ff0000;
}
.time_form_holder {
    margin-top: 50px !important;
    margin-left: 10px !important;
}
.congrats_sub_heading {
    color: #667689;
    font-size: 24px;
    font-family: 'Rubik', sans-serif !important;
    font-weight: 400;
    margin-left: 35px;
}
.medication_card {
    .medication-container {
        .each_box_holder {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }
}
.cstm_modal {
    .intake-modal-container {
        padding: 0 !important;
    }
    .heading_holder {
        height: auto !important;
        justify-content: flex-start !important;
        gap: 8px;
        padding: 16px;
    }
}
.cstm_label_holder {
    display: inline-block;
    font-family: 'Rubik', sans-serif !important;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
}
.each_form_container {
    display: flex;
    flex-wrap: wrap;

    .each_input_holder {
        &:nth-last-child(2) {
            width: calc(100% - 100px);
        }
    }
    .input_holder {
        width: 100% !important;

        input {
            &.each_input_holder {
                width: 100% !important;
            }
        }
    }
    .each_half_holder {
        width: calc(50% - 8px);

        input {
            width: 100%;
        }
    }
    
    .basic_icon_outer_holder {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 16px;

        .basic_icon_holder {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 6px;
            border: 1px solid #E0E0E0;
            border-radius: 4px;
            cursor: pointer;

            svg {
                font-size: 18px;
                color: #303E4E;
            }
        }
    }
}
.non_edited_holder {
    width: calc(100% - 40px);

    .each_form_container {
        display: flex;
        gap: 16px;
    }
}
.edited_holder {
    width: 100%;

    .each_form_container {
        display: flex;
        gap: 16px;
    }

    & + .edit_icon_holder {
        display: none;
    }
}
.ba-input-fields {
    svg {
        color: #303E4E;
        font-size: 18px;
    }
}
.outer_stepper_holder {
    .middle_portion {
        .booking_boxes {
            .select-a-time-body.grey_btn_like_holder {
                display: inline-flex !important;
                .calenderwrapper {
                    div {
                        .rdrCalendarWrapper {
                            background: #fff !important;
                            border: none !important;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 767px) {
    .d-lg-none {
        display: none !important;
    }
}
@media (max-width: 1280px) {
    .intake-modal-container {
        width: 90%;
    }
}
@media (max-width: 767px) {
    .app_header,
    .app_appointmentdeatis_header,
    .doctor_care,
    .d-md-none {
        display: none !important;
    }
    ::placeholder {
        font-family: 'Rubik', sans-serif !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        color: #989CA8 !important;
    }
    .w-md-100 {
        width: 100%;
    }
    .common_information_holder {
        width: 100%;
        text-align: center;
        font-family: 'Rubik', sans-serif;
        font-size: 14px;
        font-weight: 400;
        padding: 8px 20px;
        border-top: 1px solid #e0e0e0;
        box-sizing: border-box;

        .heading {   
            max-width: 290px; 
            margin: 0 auto;
            font-weight: 400;
            color: #000000;
            margin-bottom: 8px;
        }
        .content {
            color: #5571C6;
            text-decoration: none;
        }
    }
    .cstm_modal {
        .grid_container {
            padding: 16px !important;
            margin-top: 0 !important;
            max-height: calc(100% - 115px);
            overflow: auto;
        }
        .modal_title {
            font-family: 'Rubik', sans-serif !important;
            font-size: 16px !important;
            font-weight: 500 !important;
        }
        .bottom_btn_holder {
            position: absolute;
            left: 0;
            bottom: 0;
            background: #fff;
        }
        .MuiDialog-paperFullWidth {
            width: calc(100% - 20px);
            margin: 0;
        }
        .edit_icon_holder {
            svg {
                background-color: transparent !important;
                width: 24px !important;
                height: 24px !important;
            }
        }
        .intake-modal-container {
            padding: 0 !important;
        }
        .heading_holder {
            height: auto !important;
            justify-content: flex-start !important;
            gap: 8px;
            padding: 16px;
        }
    }
    .each_form_container {
        input {
            font-size: 14px;
        }
        .each_half_holder {
            &:nth-child(1) {
                width: calc(80% - 8px);
            }
            &:nth-last-child(2) {
                width: calc(20% - 8px);
            }
        }
    }
    .each_input_holder {
        .MuiOutlinedInput-root {
            padding: 7px !important;
            min-height: 32px !important;
        }
    }
    .edited_holder {    
        .each_form_container {
            display: flex;
            flex-wrap: wrap;
            .each_input_holder {
                .MuiOutlinedInput-root {
                    padding: 7px !important;
                }
                &:nth-last-child(2) {
                    width: calc(100% - 100px);
                }
            }
        }
    }
    .non_edited_holder {    
        .each_form_container {
            .each_input_holder {
                &:nth-last-child(2) {
                    width: calc(100% - 100px);
                }
            }
        }
    }
    .intake-modal-container {
        .tabText {
            .MuiTab-wrapper {
                font-family: 'Rubik', sans-serif !important;
                font-size: 14px !important;
                font-weight: 500 !important;
                color: #303E4E !important;
            }
        }
    }
    .typolabel_holder {
        font-family: 'Rubik', sans-serif !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        color: #667689 !important;

        & > span {
            font-family: 'Rubik', sans-serif !important;
            font-size: 12px !important;
            font-weight: 400 !important;
            color: #667689 !important;
        }
    }
    .cstm_typography_inputval {
        font-family: 'Rubik', sans-serif !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #000 !important;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .cstm_grid_container_holder {
        font-family: 'Rubik', sans-serif !important;
        font-size: 14px !important;
        color: #000 !important;

        h3, h4 {
            font-family: 'Rubik', sans-serif !important;
            font-size: 16px !important;
            font-weight: 600 !important;
            text-align: left !important;
            color: #000 !important;
        }
        li {
            word-break: break-word;
        }
    }
    .sign-in-wrapper {
        width: 100%;
        padding: 16px !important;

        .form-wrapper {
            border: none !important;
            height: auto !important;

            .logo-wrapper {
                margin: 0 !important;
                width: 100% !important;

                .form-logo {
                    max-width: 120px !important;
                }
            }
            .form {
                margin: 0 !important;

                .sign-in-text-input {
                    svg {
                        font-size: 20px !important;
                    }
                }
            }
            .form-footer {
                margin: 0 !important;
            }
        }
    }
    .w-md-100 {
        width: 100% !important;
    }
    .fullwidth_card {
        width: calc(100% - 62px) !important;
        height: 100% !important;
        margin: 0 !important;
        margin-bottom: 16px !important;
    }
    .appointment_Bill_Card, 
    .msg_refill_Card {
        margin-top: 0 !important;
    }
    .appointment_Bill_Card {
        .fullwidth_card {
            // height: 100% !important;
        }
    }
    .dashboard_Home {
        padding: 16px !important;

        .dashboard_Home_Struct {
            margin: 0 !important;

            .leftSide_Dashboard {
                width: 100% !important;
                max-width: 100% !important;
                flex-basis: 100% !important;
                padding: 0 !important;
                margin-bottom: 16px !important;
                
                .leftSide_Text {
                    .teleMed_Logo {
                        max-width: 120px !important;
                        margin-bottom: 16px !important;
                    }
                }
                .leftBottom {
                    display: none !important;
                }
            }
            .rightSide_Dashboard {
                width: 100% !important;
                max-width: 100% !important;
                flex-basis: 100% !important;
                padding: 0 !important;

                .notification {
                    margin-bottom: 16px !important;

                    .notificationCard {
                        width: calc(100% - 27px) !important;
                    }
                }
            }
        }
    }
    .member_profile {
        height: 100% !important;

        .member_profileCard {
            width: 100% !important;
            height: 100% !important;
        }
    }
    .left-side-portion {
        display: flex;
        align-items: center;
        position: relative;
        margin-left: 0;
        margin-top: 0;

        .middle_portion_loader {
            width: 100%;
            height: calc(100vh - 32px);
            padding: 16px;
            margin: 0;

            .text_holder {
                text-align: center !important;
                font-family: 'Rubik', sans-serif !important;
                font-size: 25px !important;
                font-weight: 500 !important;
                line-height: 35px !important;
            }
        }
    }
    .outer_stepper_holder {
        height: 100vh;
        @supports (-webkit-touch-callout: none) {
            /* CSS specific to iOS devices */ 
            height: -webkit-fill-available;
        }

        .booking_appointment {
            padding: 0 !important;

            .wizard_outer_holder {
                .image_holder {
                    display: none;
                }
                .cstm_stepper_holder {
                    display: block;

                    .each_step_holder {
                        & > .Mui-disabled,
                        &.Mui-completed {
                            display: none;
                        }
                        .each_step_label {
                            .MuiStepLabel-iconContainer {
                                display: none;
                            }
                        }
                        .MuiStepLabel-label {
                            width: 100%;
                            position: fixed;
                            top: 0;
                            left: 0;
                            background: #fff;
                            z-index: 100;
                            margin-top: 0;

                            .steps_info_holder {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                font-family: 'Rubik', sans-serif;
                                font-size: 12px;
                                font-weight: 400;
                                color: #303E4E;
                                padding: 15px;
                                border-bottom: 2px solid #e4e4e4;
                                text-transform: capitalize;

                                span {
                                    color: #616161;
                                }
                            }
                        }
                    }
                }
            }
        }
        .middle_portion {
            flex-direction: column;
            justify-content: space-between;
            // flex-wrap: wrap;
            // height: 100vh;
            margin-left: 0;
            // margin-top: 50px;
            padding: 50px 20px 0;
            min-height: calc(100vh - 60px) !important;
            max-height: calc(100vh - 140px);
            overflow: auto;
            box-sizing: border-box;

            @supports (-webkit-touch-callout: none) {
                /* CSS specific to iOS devices */ 
                min-height: -webkit-fill-available !important;
            }

            .booking_boxes {
                width: 100%;
                // min-height: calc(100vh - 206px);
                align-items: flex-start;
                justify-content: start;
                gap: 16px !important;
                margin: 0 !important;

                &.intake_form_box {
                    padding-bottom: 20px;
                }
                &.provider_component {
                    height: auto !important;
                }
                .heading_holder {
                    margin-bottom: 0;
                }
                .reason_visit_body {
                    min-height: auto !important;
                    margin-bottom: 20px;
                }
                .booking_box {
                    width: auto;
                    height: auto;
                    border: none;
                    margin-bottom: 0 !important;

                    .MuiTypography-root {
                        font-family: 'Rubik', sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        color: #303e4e !important;
                    }
                }
                .provider_header {
                    p {
                        font-family: 'Rubik', sans-serif !important;
                        font-size: 12px !important;
                        font-weight: 400;
                        color: #303e4e !important;
                    }
                }
                .grey_btn_like_holder {
                    width: 100% !important;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background: transparent !important;
                    border: 1px solid #E0E0E0 !important;
                    border-radius: 4px !important;
                    // width: auto !important;
                    height: auto !important;
                    padding: 5px 12px !important;
                    font-family: 'Rubik', sans-serif !important;
                    font-size: 14px !important;
                    font-weight: 500;
                    color: #303e4e !important;
                    flex-shrink: unset !important;
                }
                .provider_filter {
                    flex-wrap: wrap;
                    gap: 12px !important;

                    .MuiChip-root {
                        font-size: 12px !important;
                        font-weight: 400;
                        padding: 3px 12px !important;
                        border-radius: 4px !important;
                    }
                }
                .provider_card_container {
                    overflow: unset !important;
                    padding: 0 !important;
                }
                .congrats_sub_heading {
                    font-size: 14px !important;
                    font-weight: 400 !important;
                    margin-left: 0 !important;
                }
                .card-raw {
                    width: 100% !important;
                    padding: 0 !important;

                    .located_state_autocomplete {
                        width: 100% !important;

                        .MuiFormControl-root {
                            .MuiOutlinedInput-root {
                                width: 100% !important;

                                .MuiOutlinedInput-input {
                                    font-family: 'Rubik', sans-serif !important;
                                    font-size: 14px !important;
                                    padding: 0 !important;
                                }
                            }
                        }
                    }
                }
                .provider_card {
                    width: calc(100% - 32px) !important;
                    height: 100% !important;
                    margin-bottom: 24px !important;
                    padding: 16px !important;
                    border: 1px solid #E0E0E0 !important;
                    border-radius: 4px !important;

                    &.intake_form_card {
                        width: 100% !important;
                        height: auto !important;
                        border: none !important;
                        padding: 0 !important;

                        .intake_form_container {
                            height: auto !important;
                            gap: 16px !important;
                        }
                    }
                    .question {
                        .question_text {
                            font-family: 'Rubik', sans-serif !important;
                            font-size: 14px !important;
                            font-weight: 500 !important;
                            color: #303e4e !important;

                            &.sub_heading_text {
                                font-family: 'Rubik', sans-serif !important;
                                font-size: 16px !important;
                                font-weight: 500 !important;
                                color: #000 !important;
                            }
                            & + .input_holder {
                                width: 100% !important;
                                margin-left: 0 !important;
                                margin-top: 10px !important;
                            }
                        }
                        .intake_form_radio_group {
                            gap: 0;
                        }
                        .answer_boxes {
                            flex-wrap: wrap;
                            gap: 0;

                            .MuiFormControlLabel-root {
                                width: 100%;
                            }
                        }
                        .cstm_label {
                            width: 100%;

                            &.MuiFormControlLabel-root {
                                .MuiFormControlLabel-label {
                                    font-family: 'Rubik', sans-serif !important;
                                    font-size: 14px !important;
                                    font-weight: 400 !important;
                                }
                            }                            
                        }
                    }
                    .card_content {
                        padding: 0;
                    }
                    .time_select_container {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 16px;
                        height: auto !important;

                        .time_item {
                            width: auto !important;
                            height: auto !important;
                            padding: 5px 10px !important;
                            margin: 0 !important;
                            border: 1px solid #E0E0E0 !important;
                            border-radius: 4px !important;
                            font-family: 'Rubik', sans-serif !important;
                            font-size: 14px !important;
                            font-weight: 500 !important;
                            color: #303E4E !important;

                            &.time_item_selected {
                                color: #fff !important;
                            }
                        }
                    }
                    .provider_card_content {
                        display: block;
                        position: relative;

                        .left_section {
                            margin-bottom: 24px;

                            &.mb-0 {
                                margin-bottom: 0;
                            }
                            img {
                                width: 64px;
                                height: 64px;
                                border-radius: 100%;
                                object-fit: cover;
                            }
                            .about_doctor {
                                & > span {
                                    flex-wrap: wrap;
                                    gap: 4px !important;
                                }
                                .name {
                                    width: 100%;
                                    font-family: 'Rubik', sans-serif !important;
                                    font-size: 14px !important;
                                    font-weight: 500 !important;
                                    color: #000 !important;
                                }
                                .practice {
                                    width: 100%;
                                    font-family: 'Rubik', sans-serif !important;
                                    font-size: 10px !important;
                                    font-weight: 400 !important;
                                    color: #000 !important;
                                }
                            }
                        }
                        .about_doctor.more_info_holder,
                        .more_info_holder {
                            .practice {
                                width: 100%;
                                font-family: 'Rubik', sans-serif !important;
                                font-size: 14px !important;
                                font-weight: 400 !important;
                                color: #000 !important;
                            }
                        }
                        .right_section {
                            &.position-md-static {
                                position: static;
                            }
                            button {
                                font-family: 'Rubik', sans-serif !important;
                                font-size: 14px !important;
                                font-weight: 500 !important;
                                width: 100% !important;
                                height: 100% !important;
                                padding: 5px 12px !important;
                                border-radius: 4px !important;

                                &.close_button {
                                    width: auto !important;
                                    height: auto !important;
                                    top: 0 !important;
                                }
                            }
                        }
                    }
                }
                .appo_details_title,
                .heading_label {
                    font-family: 'Rubik', sans-serif !important;
                    font-size: 12px !important;
                    font-weight: 500 !important;
                    color: #303e4e !important;
                    margin-bottom: 8px;

                    &.congrats_title,
                    &.bigger_text {
                        font-size: 14px !important;
                    }
                }
                .medication_card {
                    width: 100%;
                    padding: 0;
                    border: none;

                    .medication-container {
                        max-height: 100%;
                        overflow: auto;
                        padding: 0;
                        gap: 16px;

                        .each_box_holder {
                            width: calc(100% - 34px);
                            display: block;
                            padding: 16px;
                            border: 1px solid #E0E0E0;
                            border-radius: 8px;

                            .each_holder {
                                margin-bottom: 16px;

                                .cstm_label_holder {
                                    font-family: 'Rubik', sans-serif;
                                    font-size: 12px;
                                    font-weight: 500;
                                    color: #303e4e;
                                    margin-bottom: 8px;
                                }
                            }
                            .icon_position {
                                width: 100% !important;
                                opacity: 1 !important;

                                .delete_icon {
                                    display: inline-flex;
                                    align-items: center;
                                    gap: 4px;
                                    padding: 5px 12px;
                                    border: 1px solid #E0E0E0;
                                    border-radius: 4px;
                                    font-family: 'Rubik', sans-serif;
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #303E4E;
                                }
                            }
                        }
                    }
                }
                .reason_for_visit {
                    width: calc(100% - 20px);
                }
                .terms {
                    width: 100% !important;
                }
                .step-headers {
                    font-size: 20px !important;
                    line-height: 28px !important;
                    color: #303E4E !important;
                }
                .select-a-time-body-header {
                    display: none;
                }
                .select-a-time-body {
                    display: block !important;

                    .heading_label {
                        font-family: 'Rubik', sans-serif;
                        font-size: 14px;
                        font-weight: 500;
                        color: #303e4e;
                        margin-bottom: 10px;
                    }
                    .MuiDivider-vertical {
                        display: none;
                    }
                    .time_form_holder {
                        margin-top: 20px !important;
                        margin-left: 0 !important;
                    }
                }
            }
        }
        .wizard_btn_holder {
            width: calc(100% - 40px);
            position: fixed;
            left: 0;
            bottom: 0;
            z-index: 100;
            margin: 0;
            padding: 13px 20px;
            background: #fff;
            border-top: 2px solid #e0e0e0;

            .booking_buttons {
                button {
                    font-family: 'Rubik', sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    width: 50%;
                    height: auto !important;
                    padding: 5px 12px !important;
                    border-radius: 4px !important;
                }
            }
        }
    }
}
@media (max-width: 370px) {
    .outer_stepper_holder {
        .middle_portion {
            .booking_boxes {                
                .select-a-time-body {
                    .calenderwrapper {
                        div {
                            .rdrCalendarWrapper {
                                padding: 10px !important;

                                .rdrMonths {
                                    width: 21em;

                                    .rdrMonth {
                                        width: 21.83em;
                                    }
                                    .rdrWeekDays {
                                        width: 22em;
                                    }
                                    .rdrDay {
                                        width: 11%;

                                        .rdrDayNumber {
                                            margin-bottom: 14px !important;

                                            span {
                                                font-size: 12px !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
}