@import '../../../styles/themes/color.module.scss';
// .search_bar {
//     box-sizing: border-box;
//     height: 50px;
//     width: 100%;
//     border: 1px solid $secondaryGray;
//     border-radius: 4px;
//     background-color: $primaryWhite;
//     margin-right: 20px;
//     padding: 10px;
//     outline: none;
// }
#MuiOutlinedInput-input-1 {
    height: 8px !important;
    padding-left: 34px !important;
}
.con {
    .serc-input {
        width: 100%;
        position: relative;
        display: inline-block;
    }
    .search-box {
        position: absolute;
        left: -21px;
        top: -34px;
        width: 30px;
        z-index: 1;
        color: $primaryGray;
        height: 20px;
    }
    .search-sx {
        width: 100%;

        float: right;
        background: white;

        text-indent: 30px;
    }
}
