// /* latin-ext */
// @font-face {
//     font-family: 'Lato';
//     font-style: normal;
//     font-weight: 400;
//     src: url(https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjxAwXjeu.woff2) format('woff2');
//     unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
//   /* latin */
//   @font-face {
//     font-family: 'Lato';
//     font-style: normal;
//     font-weight: 400;
//     src: url(https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjx4wXg.woff2) format('woff2');
//     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
//   }
//   /* latin-ext */
//   @font-face {
//     font-family: 'Lato';
//     font-style: normal;
//     font-weight: 700;
//     src: url(https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh6UVSwaPGR_p.woff2) format('woff2');
//     unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
//   /* latin */
//   @font-face {
//     font-family: 'Lato';
//     font-style: normal;
//     font-weight: 700;
//     src: url(https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh6UVSwiPGQ.woff2) format('woff2');
//     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
//   }

/* lato-regular - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/lato-v22-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/lato-v22-latin-regular.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */
            url('../fonts/lato-v22-latin-regular.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/lato-v22-latin-regular.woff')
            format('woff'),
        /* Modern Browsers */
            url('../fonts/lato-v22-latin-regular.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/lato-v22-latin-regular.svg#Lato')
            format('svg'); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/lato-v22-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/lato-v22-latin-700.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */
            url('../fonts/lato-v22-latin-700.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/lato-v22-latin-700.woff')
            format('woff'),
        /* Modern Browsers */
            url('../fonts/lato-v22-latin-700.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/lato-v22-latin-700.svg#Lato')
            format('svg'); /* Legacy iOS */
}
/* lato-900 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/lato-v22-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/lato-v22-latin-900.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */
            url('../fonts/lato-v22-latin-900.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/lato-v22-latin-900.woff')
            format('woff'),
        /* Modern Browsers */
            url('../fonts/lato-v22-latin-900.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/lato-v22-latin-900.svg#Lato')
            format('svg'); /* Legacy iOS */
}
