.lab_result_main_container {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.lab_result_sub_container {
    width: 1000px;
    height: 600px;
}

.lab_result_back_container {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    margin-bottom: 5px;
    p {
        color: #303e4e !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 24px !important;
    }
}

.lab_result_body {
    border-radius: 5px;
    border: 1px solid #bfbdbf;
    background: #fff;
    padding: 25px;
    height: 650px;
}

.lab_result_body_header {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: 8px;
    border-bottom: 1px solid #eaecf0;
    p {
        color: #303e4e !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 22px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 24px !important;
    }
}

.lab_results_body_contents {
    padding: 15px 25px;
    display: flex;
    gap: 15px;
    flex-direction: column;
}

.lab_results_tab_contents {
    display: flex;
    width: 100%;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
}

.lab_results_tab_content_headers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .lab_results_tab_content_header {
        color: #303e4e !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 24px !important;
        text-transform: capitalize;
    }
    .lab_results_tab_content_sub_header {
        color: #667689 !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 15px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 24px !important;
    }
}

.lab_results_tab_content_count {
    display: flex;
    align-items: center;
    gap: 5px;
    p {
        color: #667689 !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 15px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 24px !important;
    }
}

.lab_result_cards_loader {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 400px;
}

.lab_results_card_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 575px;
    padding-right: 10px;
    overflow-y: scroll;
}

.lab_results_cards {
    border-radius: 8px;
    border: 1px solid #e9ebed;
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.07);
    padding: 15px;
    width: 850px;
    display: flex;
    justify-content: space-between;
    p {
        text-transform: capitalize;
    }
}

.lab_results_cards_contents {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 7px;
}

.lab_results_card_type {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: 138px;
    cursor: pointer;
    p {
        color: #d22121 !important;
        font-family: Rubik !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 140% !important;
    }
}

.lab_results_tab_content_third_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.lab_result_tab_content_sorting {
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    cursor: pointer;
    p {
        color: #667689 !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 15px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 24px !important;
    }
}

.lab_results_date_fields {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 45px;
    span {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
}
