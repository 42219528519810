@import '../../../../styles/themes/color.module.scss';

$border-gray: $secondaryGray;
body {
    font-size: 14px;
}
.td_listing {
    width: 126px;
}

.header_name {
    float: left;
    margin-left: 8px;
}
.header_icon {
    float: right;
}

.listing_disable {
    background-color: unset !important;
    color: #c2c5ce !important;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    height: 38px;
    width: 156px;
    align-items: center;
    font-weight: bold;
    font-weight: 14px;
}
.listing_action_link {
    height: 38px;
    width: 156px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    color: $primaryDarkBlue;
    font-size: 14px;
}

.listing_name_profile {
    display: inline-flex;
}
.listing_method {
    display: inline-flex;
    width: 182px;
}
.provider_listingname {
    margin-top: 8px;
    margin-left: 10px;
}
.namespacing_provide {
    padding-left: 4px;
}
.listing_divsection {
    margin-bottom: 20px;
}
