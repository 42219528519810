@import '../../styles/themes/color.module.scss';
.document_container {
    width: 100%;
    &__header {
        display: inline-flex;
        width: 100%;
        height: 40px;
        align-items: center;
        justify-content: space-between;
        &-wrapper {
            display: inline-flex;
            align-items: center;
        }
        &-label {
            font-weight: bold;
            display: flex;
            height: 100%;
            align-items: center;
            color: $primaryDarkBlue;
            margin-left: -9px;
        }
        &__actions {
            display: inline-flex;
            flex-wrap: nowrap;
            white-space: nowrap;
            justify-content: flex-end;
        }

        &__action {
            display: inline-flex;
            flex-wrap: nowrap;
            white-space: nowrap;
            min-width: 68%;
            justify-content: flex-end;
        }
        &-back_button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-right: 20px;
            &-icon {
                height: 24px;
                width: 24px;
                margin-right: 4px;
            }
            &-label {
                font-size: 18px;
                font-weight: bold;
                color: $primaryDarkBlue;
            }
        }
    }
    &__body {
        &__pagination {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            .MuiPaginationItem-page.Mui-selected {
                background: $primaryDarkBlue;
                color: white;
            }
        }
    }
}

.Icons {
    display: inline-flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    min-width: 100%;
    justify-content: space-evenly;
    align-items: center;
    svg {
        color: $primaryBlue;
        height: 24px;
        width: 24px;
        cursor: pointer;
    }
}
.Iconsss {
    height: 24px;
    width: 24px;
    color: $primaryBlue;
    cursor: not-allowed;
    pointer-events: all !important;
}
.document_Model_EditContainer {
    height: 340px;
    width: 467px;
    border-radius: 4px;
    background-color: $primaryWhite;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.document_Model_Container {
    height: 415px;
    width: 520px;
    border-radius: 4px;
    background-color: $primaryWhite;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.document_container_upload {
    height: 415px;
    width: 510px;
    border-radius: 4px;
    background-color: $primaryWhite;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.document_container_selected {
    height: 445px;
    width: 510px;
    border-radius: 4px;
    background-color: $primaryWhite;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.documentmodelContainer {
    height: 376px;
    width: 467px;
    background-color: $primaryWhite;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}
.documentHeader {
    height: 45px;
    width: 100%;
    background-color: rgb(28, 42, 84);
    margin-top: 0px;
    border-radius: 4px 4px 0px 0px;
}
.modellabel {
    height: 29px;
    width: 200px;
    color: $primaryWhite;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    padding: 10px;
}
.modelclose {
    height: 25px;
    width: 33px;
    float: right;
    margin-top: -35px;
    padding: 1px;
}

.dropdownModel {
    min-width: 410px !important;
}
.documentheader {
    min-width: 160px !important;
    box-sizing: border-box;
    height: 52px;
    border: 1px solid $secondaryGray !important;
    border-radius: 4px;
    background-color: $primaryWhite !important;
}
.documentSelect {
    box-sizing: border-box;
    height: 40px;
    border: 0px solid $secondaryGray !important;
    border-radius: 4px;
    background-color: $primaryWhite !important;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    position: relative;
    font-size: 0.9rem;
    box-sizing: border-box;
    align-items: center;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
}
.modeltextfiled {
    margin-top: 40px !important;
}
.textfilemodel {
    height: 40px;
    width: 411px;
    box-sizing: border-box;
    border: 1px solid $secondaryGray !important;
    border-radius: 4px;
    background-color: $primaryWhite !important;
    margin: 0 20px !important;
    border-bottom: 0px 0px 0px 0px;
    background: none !important;
}

.documentName {
    height: 34px;
    width: 130px;
    color: $primaryGray;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: 0;
    margin-left: 22px;
    line-height: 34px;
    padding: 0px;
}
.search_input {
    width: 528px;
}

.admin_header {
    display: inline-flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: space-between;
}
.header_ad {
    display: inline-flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: space-between;
}
.bottomborder {
    border-bottom: 2px solid $primaryDarkBlue;
}
.header-clear-btn {
    font-size: 16px;
    color: $primaryDarkBlue;
    cursor: pointer;
    margin-left: -10px;
    margin-top: 28px;
    margin-right: 10px;
}

.deletetab {
    display: inline-flex;
    width: 80%;
    margin-left: 48px;
    margin-top: 110px;
    align-items: center;
    justify-content: space-between;
}
.deletetext {
    height: 18px;
    width: 93%;
    color: #5670c6;
    font-family: 'Rubik', sans-serif;
    font-size: 27px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    margin-top: 70px;
}
.alertmessage {
    color: red;
    font-size: 10px;
    font-family: 'Rubik', sans-serif;
    margin-left: 30px;
}

.selectmodelupload {
    width: 305px !important;
    border: 1px solid $secondaryGray !important;
    border-radius: 5px;
    margin-left: 24px;
    margin-top: 5px;
}
.editselectmodelforedit {
    width: 410px !important;
    border: 1px solid $secondaryGray !important;
    border-radius: 5px;
    margin-left: 24px;
    margin-top: 5px;
}
.doctableview {
    margin-top: 10px;
    margin-left: 10px;
}
.doctableviewpersonawise {
    border-radius: 4px;
    background-color: $primaryWhite;
    box-shadow: 0 0 13px 0 rgb(222 231 241 / 50%);
    padding: 10px 10px 10px 10px;
}
.uploaddoclabel {
    height: 34px;
    width: 125px;
    color: $primaryGray;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 34px;
    text-align: right;
    margin-left: 24px;
    margin-top: 10px;
}

.uploaddocmodellabel {
    height: 29px;
    width: 200px;
    color: $primaryWhite;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    padding: 10px;
    margin: 0px;
}
.uploaddocbuttonlabel {
    height: 34px;
    width: 235px;
    color: $primaryGray;
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 34px;
    margin-left: 27px;
    margin-top: 5px;
}
.uploadheaderbutton {
    height: 40px;
    width: 160px;
    border-radius: 4px;
    background-color: $primaryDarkBlue;
}
.docbuttontype {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 71px;
    width: 33%;
    box-sizing: border-box;
    color: #b2c0cf;
    box-shadow: 0 0 13px 0 $shadow;
    border-radius: 4px;
    background-color: #eff2fa;
    cursor: pointer;
    font-size: 14px;
}
.border-bottom {
    border-bottom: 2px solid $primaryDarkBlue;
}
.uploadbuttondoc {
    height: 40px;
    width: 25%;
}
.uploadbuttondoconly {
    height: 40px;
    width: 22%;
}
.disablebutton {
    margin-left: 27px;
    height: 40px;
    min-width: 160px !important;
    font-size: 14px !important;
}

.btn-type-activedoc {
    color: $primaryDarkBlue;
    background-color: $primaryWhite;

    span {
        border-bottom: 2px solid $primaryDarkBlue;
        height: 61px;
        width: 114px;
        justify-content: center;
        display: flex;
        align-items: center;
        color: $primaryDarkBlue;
        font-weight: bold;
        transition: color 100ms ease-in-out 0s,
            font-weight 100ms ease-in-out 0s;
    }
}

.cirrcularpredocupload {
    color: $primaryWhite !important;
    width: 20px !important;
    height: 20px !important;
}
.filepicked {
    display: flex;
    padding-left: 20px;
    width: 70%;
    margin-top: 15px;
}
.filepickerscroll {
    overflow-y: auto;
    max-height: 140px;
    min-height: 140px;
}
.continuebutton {
    width: 150px;
    height: 45px;
}

.attachfilename {
    min-width: 300px;
    color: $primaryDarkBlue;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 23px;
}
.attachremove {
    padding-left: 1px;
}

.errormoreupload {
    margin-left: 20px;
    color: $primaryDarkBlue;
    font-family: 'Rubik', sans-serif;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 24px;
}
.errormoreuploadmb {
    color: #ff0000;
    font-family: 'Rubik', sans-serif;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 10px;
}
.dissablebutton {
    background-color: gray;
    min-width: 160px;
}

.cirrcularpredoc {
    color: $primaryWhite !important;
    width: 25px !important;
    height: 25px !important;
}

.doctexttransform {
    text-transform: initial;
}
p {
    margin: 0px;
}
