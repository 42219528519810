@import '../../../styles/themes/color.module.scss';
.card {
    // box-shadow: 0 0 2px 5px #dee7f180 !important;
    box-shadow: 0 0 13px 0 rgba(222, 231, 241, 0.5);

    &__header {
        height: 60px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 78%;

        &__title {
            display: inline-flex;
            align-items: center;
            &-icon {
                width: 24px;
                margin-left: 20px;
                margin-right: 20px;
            }

            &-label {
                font-size: 18px;
                color: $primaryDarkBlue;
                font-weight: bold;
            }
        }

        &__action {
            display: inline-flex;
            align-items: center;
        }
    }

    &__body {
        width: 100%;
        height: calc(100% - 70px);
        margin-bottom: 10px;
    }
}
