@import '../../styles/themes/color.module.scss';
@import '../../styles/themes/sizing.scss';
.container {
    .astrik {
        color: red;
    }
    .form-wrapper {
        .form {
            margin: 2rem 6rem;
            h1 {
                color: $primaryDarkBlue;
                margin-top: 5px;
                font-size: 24px;
                margin-bottom: 50px;
            }
            .input-label {
                font-size: 14px;
                padding-bottom: 8px;
            }
            .text-input {
                width: 100%;
                color: #777777;
                margin-bottom: 2rem;
                .MuiFormHelperText-root {
                    color: #f44336;
                    margin-right: 0;
                    margin-left: 0;
                }
                .resendotp-grayed {
                    color: #777777;
                    cursor: not-allowed;
                    text-align: right;
                }
                .link-blue {
                    color: $primaryDarkBlue;
                }
            }
            .submit {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 30px;
                .btn-primary {
                    font-weight: 700;
                    width: 130px;
                }
                .btn-error {
                    font-weight: 500;
                    color: $primaryWhite !important;
                    background-color: #777777 !important;
                    text-transform: capitalize !important;
                    width: 130px;
                    cursor: not-allowed;
                }
                h5 {
                    color: #777777;
                    margin-top: 15px;
                    font-weight: normal;
                    font-size: 12px;
                    a {
                        color: $primaryDarkBlue;
                        margin-left: $space-5;
                        font-weight: 700;
                        font-size: 12px;
                    }
                }
            }
            .error-login {
                color: #777777;
                text-align: center;
                margin-top: 94px;
                font-weight: normal;
                font-size: 12px;
                a {
                    font-weight: 700;
                    font-size: 12px;
                }
            }
            .date-picker {
                width: 100%;
                // .MuiOutlinedInput-input {
                //     padding: 10.5px 14px;
                // }
                &.MuiFormControl-marginNormal {
                    margin-top: 0;
                    margin-bottom: 2rem !important;
                }
            }
            .form-gender {
                margin-bottom: 1rem;
            }
        }
    }
}
.MuiFormHelperText-root.MuiFormHelperText-contained {
    margin-left: 0;
    margin-right: 0;
}
@media only screen and (max-width: 768px) {
    .container .form-wrapper .form h1 {
        font-size: 18px;
    }
}
@media only screen and (max-width: 582px) {
    .MuiContainer-root {
        // background: $success;
        // height: calc(100vh - 80px);
        form {
            background: $primaryWhite;
        }
    }
    .container .form-wrapper .form h1 {
        font-size: 16px;
        margin-bottom: 25px;
    }
}
