.con-dashboard {
    width: auto;
    height: 100%;
    margin-left: 200px;
    margin-top: 100px;
}

.crd-sx {
    height: 327px;
    width: 483px;
    float: left;
    margin-right: 25px;
    margin-bottom: 25px;

    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 0 13px 0 rgba(222, 231, 241, 0.5);
}
.spacer {
    margin-bottom: 20px;
}

.sidebar-menu {
    z-index: 100;
    margin-left: 10px;
    margin-top: -90px;
    height: 30px;
    position: absolute;
}
@media screen and (max-width: 720px) {
    .container-1 {
        margin-left: 5%;
        margin-right: 5%;
        width: 90%;
    }
    .crd-sx {
        width: 100%;
    }
    .search-sx {
        width: 100% !important;
        height: 51px !important;

        float: left;
    }
    .spacer {
        margin-bottom: 20px;
    }
}
