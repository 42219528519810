@import '../../../styles/themes/color.module.scss';
.superAdmincrd__header {
    cursor: pointer;
}

.scrollbar-1 {
    float: left;
    height: 729px;
    width: 100%;

    overflow-y: scroll;
    margin-bottom: 25px;
}

#force-overflow-1 {
    min-height: 728px;
}

.scrollbar {
    float: left;
    height: 318px;
    width: 100%;

    overflow-y: scroll;
    margin-bottom: 25px;
}

.force-overflow {
    min-height: 310px;
}

#wrapper {
    text-align: center;
    width: 500px;
    margin: auto;
}
.table-4:first-child {
    background-color: #f9f9f9;
}
/*
 *  STYLE 1
 */

#track__firsr::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $secondaryGray;
    border-radius: 10px;
    background-color: #f5f5f5;
}

#track__firsr::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
}

#track__firsr::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px $secondaryGray;
    background-color: $secondaryGray;
}
.hr {
    padding: 0;
    margin: 0;
}

#track_second::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $secondaryGray;
    border-radius: 10px;
    background-color: #f5f5f5;
}

#track_second::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
}

#track_second::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px $secondaryGray;
    background-color: $secondaryGray;
}
span.square {
    margin-right: 8px;
    height: 8px;
    display: inline-flex;
    align-items: center;
    &:before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
    }
}
