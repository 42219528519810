.booking_appointment {
    padding: 40px !important;
}
.time_line {
    display: flex;
    align-items: center;
    justify-content: center;
}
.booking_boxes {
    // margin-top: -300px !important;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;
    width: 70%;
    z-index: 2;
    .booking_box {
        border-radius: 5px;
        border: 1px solid #5571c6;
        background: #fff;
        width: 350px;
        height: 50px;
        margin-bottom: 20px;
    }
}

.booking_radio_group {
    .booking_box {
        margin-left: 0;
        margin-right: 0;
    }

    .booking_box:last-child {
        margin-bottom: 0;
    }
}

.middle_portion_loader {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 20%;
    align-items: center;
    width: 70%;
    margin-top: 80px;
    z-index: 2;
}

.booking_buttons {
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 12px;
    // margin-top: 40px;
    button {
        width: 510px !important;
        height: 35px !important;
        text-transform: capitalize !important;
        border-radius: 5px !important;
    }
}

.middle_portion {
    display: flex;
    // height: 100%;
    align-items: flex-start;
    justify-content: center;
    margin-left: 83px;
    position: relative;
    // height: 500px;
}
.left-side-portion {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-left: 83px;
    margin-top: 40px;
}
.provider_header {
    padding: 8px 12px;
    background: #F1FAF1;
    border: 1px solid #9FD89F;
    display: flex;
    align-items: center;
    border-radius: 4px;
    gap: 5px;
    width: max-content;
    p {
        color: #303e4e !important;
        font-family: Rubik !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
        letter-spacing: -0.064px !important;
    }
}

.provider_filter {
    display: flex;
    align-items: center;
    gap: 20px;
    .title_select {
        display: flex;
        align-items: center;
        gap: 3px;
        p {
            color: #303e4e !important;
            font-family: Inter !important;
            font-size: 16px !important;
            font-style: normal !important;
            font-weight: 500 !important;
            line-height: 24px !important;
        }
    }
    .MuiChip-root {
        padding: 4px 12px !important;
        display: flex !important;
        gap: 8px !important;
        border-radius: 8px !important;
        background-color: #e1edf3 !important;
        .MuiChip-deleteIcon {
            margin: 0px !important;
        }
    }
}

.provider_card_container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    // max-height: 100%;
    padding-right: 20px;
}

.provider_card {
    padding: 1px;
    margin-bottom: 10px;
    width: 900px;
    // height: 120px;
    border: 1px solid rgb(208, 208, 208);
    box-shadow: none !important;
    border-radius: 10px !important;
    background-color: #fff;
    .card_content {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}
.medication_card {
    padding: 10px;
    margin-bottom: 10px;
    width: 900px;

    border: 1px solid #d0d0d0;
    box-shadow: none !important;
    border-radius: 5px !important;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    .medication-container {
        max-height: 265px;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
    }
    .medication-ser {
        color: #303e4e;
        font-size: 16px;
        font-weight: 400;
    }
    .add-medication-submit {
        border: 1px dashed #15a452;
        background: #fff;
        color: #15a452;
        width: 100%;
    }
    .divider_height {
        margin: 0px 4px 0px 0px;
        background: #667689;
        height: 40px;
    }
}
.form_card {
    height: 540px !important;
}

.provider_card_diff {
    height: 320px !important;
    border-color: #5571c6 !important;
}

.provider_card_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .left_section {
        display: flex;
        align-items: center;
        gap: 16px;
        .about_doctor {
            display: flex;
            flex-direction: column;
            // margin-left: 10px;
            // gap: 8px;
            .name {
                color: #303e4e !important;
                font-family: Rubik !important;
                font-size: 16px !important;
                font-style: normal !important;
                font-weight: 500 !important;
                line-height: 24px !important;
            }
            .practice {
                color: #667689 !important;
                font-family: Rubik !important;
                font-size: 16px !important;
                font-style: normal !important;
                font-weight: 400 !important;
                line-height: 24px !important;
            }
            .details {
                color: #5571c6 !important;
                font-family: Rubik !important;
                font-size: 16px !important;
                font-style: normal !important;
                font-weight: 400 !important;
                line-height: 24px !important;
            }
        }
    }
    .right_section {
        position: relative;
        .close_button {
            position: absolute;
            right: 0;
            top: -32px;
        }
        .book_button {
            background: rgb(85, 113, 198) !important;
            text-transform: capitalize !important;
            width: 110px !important;
            height: 25px !important;
            border-radius: 5px !important;
        }
    }
}

.appo_details_card_content {
    align-items: normal !important;
    flex-direction: column !important;
    gap: 10px !important;
    height: 475px !important;
    overflow-y: scroll;
    padding: 5px 5px;
}

.doctor_care {
    position: absolute;
    width: 390px;
    left: 0;
    top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.step-headers {
    color: #303e4e !important;
    font-family: Rubik !important;
    font-size: 30px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important;
}
.step-sub-headers {
    color: #6F6F6F !important;
    font-family: Rubik !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    margin-top: 10px !important;
}

.error-message {
    color: #303e4e !important;
    font-family: Rubik !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    padding-left: 7px !important;
}

.first_time_booking_radio {
    margin-left: 10px !important;
    padding: 9px !important;
}

.located_state_autocomplete {
    width: auto !important;
    .MuiFormControl-root .MuiOutlinedInput-root {
        height: 45px !important;
        width: 776px !important;
        .MuiOutlinedInput-input {
            padding: 0px 18px !important;
            color: #303e4e !important;
            font-family: Rubik !important;
            font-size: 20px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 140% !important;
        }
    }
}

.provider_component {
    grid-gap: 0px !important;
    gap: 20px !important;
    height: 100%;
    justify-content: flex-start !important;
    margin-left: 150px;
    .body {
        display: flex;
        flex-direction: column;
        gap: 15px;
        max-height: 100%;
        padding-right: 40px;
    }
}

.time_select_container {
    // display: flex;
    flex-wrap: wrap;
    column-gap: 27px;
    row-gap: 15px;
    padding: 0px 8px;
    .loadingAvailability {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .time_item {
        width: 96px !important;
        height: 28px !important;
        border-radius: 5px !important;
        border: 0.8px solid #c6cad6 !important;
        background: #fff !important;
        color: #5571c6 !important;
        text-align: center !important;
        font-family: Inter !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 24px !important;
        margin: 8px 10px 8px 10px !important;
    }
    .time_item_selected {
        border-radius: 5px !important;
        border: 0.8px solid #4d64aa !important;
        background: #5571c6 !important;
        color: #fff !important;
    }
}

.appo_details_title {
    color: #303e4e !important;
    font-family: Rubik !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    margin-bottom: 5px !important;
}

.reason_for_visit {
    resize: none;
    display: inline-flex;
    height: 175px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #d0d0d0;
    background: #fff;
    width: 882px;
    padding: 10px;
    color: #667689;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.patient_info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    .patient_info_child {
        display: flex;
        gap: 30px;
        width: 100%;
        .patient_info_grandchild {
            // width: 45%;
            .MuiFormControl-root .MuiOutlinedInput-root {
                border-radius: 5px !important;
                border: 1px solid #bfbfbf !important;
                background: #fff !important;
                width: 414px !important;
                height: 45px !important;
                flex-shrink: 0 !important;
                padding: 0px 10px !important;
                // width: 100% !important;

                //typography
                color: #989ca8 !important;
                font-family: Rubik !important;
                font-size: 16px !important;
                font-style: normal !important;
                font-weight: 400 !important;
                line-height: 140% !important;
            }
        }
    }
    .patient_info_child_single {
        .MuiInputBase-root .MuiSelect-root {
            color: #989ca8 !important;
            font-family: Rubik !important;
            font-size: 16px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 140%;
        }
        .dropdown-placeholder {
            color: #989ca8;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }
    }
}

.field_label {
    display: flex;
    gap: 10px;
    color: #303e4e !important;
    font-family: Rubik !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 140% !important;
    p {
        color: #e81a1a;
    }
}

.address_line {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .patient_info_grandchild {
        .MuiFormControl-root {
            .MuiOutlinedInput-root {
                height: 45px !important;
                background-color: #fff !important;
            }
        }
    }
}

.address_line_last_child {
    display: flex;
    gap: 30px;
    .patient_info_grandchild {
        .MuiFormControl-root {
            .MuiOutlinedInput-root {
                width: 265px !important;
            }
        }
        .MuiInputBase-root .MuiSelect-root {
            color: #989ca8 !important;
            font-family: Rubik !important;
            font-size: 16px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 140% !important;
        }
    }
}

.MuiInputBase-root {
    color: initial !important;
    border: 1px solid #bfbfbf !important;
    color: #303e4e !important;
    font-family: Rubik !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 140% !important;
}

.MuiStepConnector-root {
    left: calc(-50% + 10px) !important;
    right: calc(50% + 10px) !important;
    .MuiStepConnector-line {
        border-color: #c6c6c6 !important;
    }
}

.image_text {
    left: 50px;
    position: absolute;
    bottom: -20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    p {
        color: #303e4e !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 15px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 24px !important;
    }
    .number {
        color: #5571c6 !important;
        font-family: Rubik !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 24px !important;
    }
}

.intake_form_card {
    height: 500px !important;
}

.intake_form_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 470px !important;
    overflow-y: scroll;
    padding-left: 2px;
}

.question {
    display: flex;
    flex-direction: column;
    .question_text {
        color: #000 !important;
        font-family: Rubik !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 140% !important;
    }
    .answer_boxes {
        display: flex;
        align-items: center;
        gap: 30px;
        .MuiButtonBase-root {
            padding-right: 0px !important;
        }
        .MuiFormControlLabel-label .MuiTypography-root {
            color: #303e4e !important;
            font-family: Rubik !important;
            font-size: 16px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 140% !important;
            margin-top: 3px;
        }
    }
}

.terms {
    width: 900px;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    .MuiFormControlLabel-root {
        margin: 0px !important;
        .MuiButtonBase-root {
            padding: 0px !important;
        }
    }
}

.intake_form_box {
    gap: 0px !important;
}

.agreement {
    margin: 0px;
    padding: 0px;
    color: #303e4e;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    span {
        color: #5571c6;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        text-decoration-line: underline;
        cursor: pointer;
    }
}

.intake_form_radio_group {
    margin-left: 20px;
    gap: 20px;
    .MuiFormControlLabel-root {
        .MuiFormControlLabel-label {
            margin-top: 2px !important;
            color: #303e4e;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }
        .MuiButtonBase-root .MuiIconButton-label svg {
            height: 24px !important;
            width: 24px !important;
        }
    }
}

.selectTheDate {
    width: 139px !important;
    height: 30px !important;
    flex-shrink: 0;
    border-radius: 8px !important;
    background: #ebeff2 !important;
    padding: 5px !important;
    cursor: pointer;
    .rdrMonthPicker select,
    .rdrYearPicker select {
        background-color: transparent !important;
    }
    .rdrSelected {
        background-color: #5571c6 !important;
    }
    .rdrDayNumber {
        top: 1px !important;
    }
}
.selectTheDate-highlighted {
    border: 1px solid #5571c6;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.dateText {
    color: #303e4e !important;
    font-family: Rubik !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 500 !important;
    padding-left: 7px;
}
.providerLoading {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 300px;
}

.no-provider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    height: 300px;
    p {
        color: #303e4e !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 24px !important;
    }
}
.select-a-time-body-header {
    display: flex;
    grid-gap: 10px;
    gap: 390px;
    width: 66%;
    p {
        color: #303e4e !important;
        font-family: Rubik !important;
        font-size: 20px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 24px !important;
    }
}

.select-a-time-body {
    .calenderwrapper div .rdrCalendarWrapper {
        border-radius: 16px;
        border: 1px solid #5571c6;
        background: #eaeffb;
        box-shadow: 0px 1px 2px 0px rgba(0, 14, 51, 0.25);
        padding: 18px !important;
    }
    .rdrMonth {
        padding: 0px !important;
    }
    .rdrMonthAndYearWrapper {
        height: fit-content !important;
        padding: 0px !important;
    }
    .rdrNextPrevButton {
        box-sizing: inherit !important;
        cursor: pointer !important;
        outline: none !important;
        background: #fff !important;
        border-radius: 50% !important;
        height: 35px !important;
        width: 35px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        margin: 0px !important;
    }
    .rdrNextButton i {
        margin: 0 0 0 2px !important;
    }
    .rdrDay.rdrDayDisabled,
    .rdrDay.rdrDayToday,
    .rdrDay.rdrDayPassive {
        background: transparent !important;
        border: 1px solid transparent !important;
    }
    .rdrDay {
        color: rgb(61, 145, 255) !important;
        margin: 2px !important;
        border-radius: 4px !important;
        border-color: transparent !important;
        background-color: #fff !important;
    }

    .rdrDay:has(.rdrSelected),
    .rdrSelected {
        background: #5571c6 !important;
    }
    .rdrDayNumber {
        margin-bottom: 10px !important;
        span {
            color: #1f1f1f;
            text-align: center;
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
        }
    }
    .rdrDayDisabled,
    .rdrDayPassive,
    .rdrDayToday {
        cursor: not-allowed !important;
        .rdrDayNumber span {
            color: rgba(0, 23, 84, 0.15) !important;
            text-align: center !important;
            font-family: Rubik !important;
            font-size: 18px !important;
            font-style: normal !important;
            font-weight: 500 !important;
            line-height: 100% !important;
        }
    }
    .rdrMonthAndYearPickers {
        gap: 1px !important;
        .rdrMonthPicker,
        .rdrYearPicker {
            margin: 0px !important;
        }
        select {
            border-radius: 6px !important;
            background: #fff !important;
        }
    }
}
