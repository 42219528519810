@import '../../../styles/themes/color.module.scss';
.profile-container {
    .profile-main-wrap {
        background: #fff;
    }
}
.profile-main-wrap {
    //   margin: 40px;
    box-shadow: 0 0 13px 0 $shadow;
    width: auto !important;
    .MuiTab-root {
        min-width: 130px !important;
        background-color: $primaryWhite;
        margin-right: 2px;
        text-transform: capitalize;
        color: $primaryGray;
        .MuiTab-wrapper {
            font-weight: 700;
        }
    }
    .bg-tab {
        height: 100px;
        background-color: $primaryDarkBlue;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 3em;
        h3 {
            color: $primaryWhite;
        }
    }
}

.tabs-main {
    .MuiTab-textColorPrimary.Mui-disabled {
        background: #eff2fa;
        margin-right: 4px;
        color: $primaryGray !important;
    }
    .MuiTab-textColorPrimary.Mui-selected {
        font-weight: 900;
        // width:80%;
        background-color: $primaryWhite;
        color: $primaryBlue;
    }
    .MuiTab-wrapper {
        // color: $primaryDarkBlue !important;
    }
    .MuiTabs-indicator {
        background: $primaryBlue !important ;
    }
}
.profile-img {
    width: 100%;
    height: auto;
    border-radius: 120px;
}
.profile-img-placeholder {
    height: 164px;
    width: 164px;
    border-radius: 50%;
    background-color: #d8d8d8;
    text-align: center;
    font-size: 46px;
    line-height: 2.9em;
    align-items: center;
    display: flex;
    justify-content: center;
}
.dFlexWrapAlignCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.dependent-form-wrap {
    display: flex;
    flex-direction: column;
    float: left;
    width: 100%;
    margin-bottom: 3em !important;
    border: 1px solid rgb(176 188 228 / 50%);
    padding-left: 20px;
}
.dependent-form-wrap:nth-child(odd) {
    float: right;
}
.opacity05 {
    opacity: 0.5;
}

.invidual-dependent-wrap {
    display: flex;
    margin-bottom: 1em !important;
    align-items: center;
    flex-wrap: wrap;
    .MuiGrid-spacing-xs-2 > .MuiGrid-item {
        padding: 0px 8px 0px 0px !important;
    }
}
.MuiGrid-spacing-xs-1 > .MuiGrid-item {
    padding: 4px 8px !important;
}
.dFlexVc {
    display: flex;
    align-items: center;
    // padding-right: 30px;
}
.dFlexHc {
    display: flex;
    justify-content: center;
}
.mb10 {
    margin-bottom: 10px;
}
.mb15 {
    margin-bottom: 15px !important;
}
.width145 {
    width: 145px;
}
.profile-name-wrap {
    margin-left: 2em;
}
.profile-name-wrap-clinic.MuiGrid-item {
    margin-top: 1em;
}
.mr1 {
    margin-right: 1em !important;
}
.ml80 {
    margin-left: 80px !important;
}
.mr75 {
    margin-right: 0.75rem;
}
.mb05 {
    margin-bottom: 0.5em !important;
}
.mb2 {
    margin-bottom: 2em !important;
}
.borderBottom {
    border-bottom: 1px solid $tertiaryGray;
}
.borderBottom:last-child {
    border-bottom: none;
}
.pb1 {
    padding-bottom: 1em;
}
.mt1 {
    margin-top: 1em !important;
}

.dFlexHC {
    display: flex;
    justify-content: center;
}
.pb3 {
    padding-bottom: 3em !important;
}
.profileTitle .upload-wrap .empty-bg {
    margin-top: 0;
}
.bio-main-wrap {
    margin-top: 2.5em;
}
.btn-primary {
    font-weight: 500;
    width: 130px;
}
.mt15 {
    margin-top: 1.5em;
}
.dFlex {
    display: flex;
}
.boldTitle {
    font-weight: bold !important;
}
.mt2 {
    margin-top: 2em !important;
}
.mt0 {
    margin-top: 0em !important;
}
.ml1 {
    margin-left: 1em !important;
}
.mt125 {
    margin-top: 1.25em !important;
}
.mr0 {
    margin-right: 0px;
}
.pr0 {
    padding-right: 0px !important;
}
.bio-edit-wrap {
    width: 100%;
    box-sizing: border-box;
    height: 130px;
    padding: 1em;
    font-family: 'Rubik', sans-serif;
    font-size: 1rem;
    line-height: 24px;
    border: 1px solid #777777;
    border-radius: 4px;
    margin-top: 0.5em;
}
.pRL1 {
    padding: 0 0.5em 0 1em;
}
.pLR1 {
    padding: 0 1em 0 0.5em;
}
.datepicker-wrap {
    border: 1px solid rgb(0 0 0 / 41%) !important;
    padding: 3px 1em !important;
    border-radius: 6px;
    box-sizing: border-box;
    .MuiInput-underline:before {
        display: none;
    }
    .MuiInput-underline:after {
        display: none;
    }
}
.plus-wrap {
    width: 35px;
    height: 35px;
    border-radius: 65%;
    border: 2px solid $primaryBlue;
    color: $primaryBlue;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
    background: $primaryWhite;
    margin-left: 15px;
}
.plus-wrap-img {
    width: 35px;
    height: 35px;
    border-radius: 65%;
    border: 2px solid $primaryWhite;
    color: $primaryWhite;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
    background: $primaryWhite;
    margin-left: 15px;
}
.plus-wrap-none {
    width: 35px;
    height: 35px;
    border-radius: 65%;
    border: 2px solid $primaryWhite;
    color: $primaryWhite;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $primaryWhite;
    margin-left: 15px;
    pointer-events: none;
}
.dFlexSB {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dFlexSpaceBw {
    display: flex;
    justify-content: space-between;
}
.mRL1 {
    margin: 0 1em;
}
.flexWrap {
    flex-wrap: wrap;
}
.pr05 {
    padding-right: 0.5em;
}
.pl05 {
    padding-left: 0.5em;
}
.prl05 {
    padding: 0 0.5em;
}
.pl1 {
    padding-left: 1em;
}
.dEnd {
    display: flex;
    justify-content: flex-end;
}
.vEnd {
    justify-content: flex-end;
}
.pb10 {
    padding-bottom: 10px;
}
.mt3 {
    margin-top: 3em !important;
}
.pTB3 {
    padding: 3em 0;
}
.textRight {
    text-align: right;
}
.ml1 {
    margin-left: 1em;
}
.textOverflow {
    inline-size: 100%;
    overflow-wrap: break-word;
}
.card-title {
    // font-size: 1.125rem !important;
    // color: $primaryDarkBlue !important;
    // font-weight: bold !important;
}
.card-title-align {
    margin-right: 16em !important;
    margin-bottom: 1em !important;
}
.mt05 {
    margin-top: 0.5em !important;
}
.profile-edit-main-wrap {
    width: 100%;
    padding: 0 0 0 4em;
    box-sizing: border-box;
    // .MuiAutocomplete-inputRoot {
    //     padding: 1em !important;
    //     .MuiAutocomplete-input {
    //         padding: 0em !important;
    //     }
    // }
}
form.profile-edit-main-wrap.editWrapper {
    padding-left: 1em;
}
.upload-wrap.client-profile-wrap {
    margin-right: 4em;
}
.css-1s2u09g-control {
    padding: 6px;
}
.ba-input-label-profile {
    text-align: right;
    margin-right: 1em;
}
.dFlexWrap {
    display: flex;
    flex-wrap: wrap;
}
.dFlexEnd {
    display: flex;
    justify-content: flex-end;
}
.pr1 {
    padding-right: 1em;
}
.provider-wrap {
    position: absolute;
    top: 85px;
    width: 33%;
    left: 364 px;
}
.subnitprobtn {
    margin-top: 5em !important;
    justify-content: flex-end;
    display: flex;
}
.errormsg {
    color: #f44336;
    font-size: 0.75rem;
    margin-top: 3px;
}
.pb3 {
    padding-bottom: 3em;
}
.primaryBtn {
    background: $primaryDarkBlue !important;
    color: $primaryWhite !important;
    margin-top: 2em !important;
    padding: 0.5em 3em !important;
}
.primaryBtn-sub {
    background: $primaryDarkBlue !important;
    color: $primaryWhite !important;
    padding: 0.5em 3em !important;
}
.floatLeft {
    float: left;
}
.floatRight {
    float: right;
}
.ba-choose-img-btn {
    box-sizing: border-box;
    height: 41px;
    width: 146px;
    border: 1px solid $primaryDarkBlue;
    border-radius: 4px;
    cursor: pointer;
    background-color: $primaryDarkBlue;
    color: $primaryWhite;
    margin: 1em 0;
    label {
        width: 133px;
        display: inline-block;
        height: 40px;
        padding-top: 10px;
        cursor: pointer;
    }
}
.ba-choose-img-btn_upload {
    box-sizing: border-box;
    // height: 41px;
    width: 146px;
    border: 1px solid $primaryDarkBlue;
    border-radius: 4px;
    cursor: pointer;
    background-color: $primaryDarkBlue;
    color: $primaryWhite;
    margin: 1em 0;
    text-align: center;
    label {
        width: 133px;
        display: inline-block;
        height: 40px;
        padding-top: 10px;
        cursor: pointer;
    }
}
.bg-tab {
    width: 100%;
    height: 100px;
    background: $primaryDarkBlue;
}
.bg-tab-client {
    display: flex;
    align-items: center;
    padding: 2em;
    height: 120px;
    background: $primaryDarkBlue;
}
.client-profile-wrap {
    padding: 1em;
}
h3.clinic-label {
    color: $primaryWhite;
}
.clinic--admin-found {
    color: $primaryGray !important;
    font-size: 18px !important;
    text-align: right;
}
.edit-main-title {
    background: $primaryDarkBlue;
    color: $primaryWhite;
    height: 100px;
    display: flex;
    align-items: center;
    padding: 1em;
    box-sizing: border-box;
}
.p6 {
    padding: 0 6px !important;
}
.p5 {
    padding: 5px 0 !important;
}
.dEndVc {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.mb1 {
    margin-bottom: 1em !important;
}
.upload-wrap {
    // position: relative;
    .empty-bg {
        max-height: 164px;
        max-width: 164px;
        // overflow: hidden;
        background: $lightGray;
        border-radius: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primaryDarkBlue;
        font-size: 42px;
        position: relative;
        margin-top: -40px;
        .profile-img {
            height: 164px;
            width: 164px;
        }
    }
    .icon-wrap {
        background: $primaryDarkBlue;
        color: $primaryWhite;
        width: 45px;
        height: 45px;
        border-radius: 84px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0px;
        bottom: -7px;
        &:hover {
            cursor: pointer;
        }
    }
}
.primaryBtn:disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
}
.primaryBtn-sub:disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
}
.attachfile-icon {
    transform: rotate(325deg);
    color: $primaryGray;
    font-size: 20px !important;
}
.delete-icon {
    color: #e83e44;
    font-size: 20px !important;
}
.img-title {
    font-size: 14px;
    color: #13191f;
}
.box-contnet {
    box-sizing: border-box;
}
.width100 {
    width: 100%;
}
.mt80 {
    margin-top: 40px !important;
}
.p2 {
    padding: 2em !important;
}
.swal2-container {
    z-index: 9999 !important;
}
.profile-dialog-main {
    .MuiDialogTitle-root {
        padding: 10px 40px;
        color: $primaryWhite;
        background: $primaryDarkBlue;
        z-index: 3;
        h2 {
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .MuiSvgIcon-root {
                cursor: pointer;
            }
        }
    }
    &__contentWrap {
        padding: 40px;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        &__imgWrap {
            width: 160px;
            height: 160px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 42px;
            color: $primaryDarkBlue;
        }
        &__detailWrap {
            padding: 0 0 1em 3em;
            width: 57%;
            &__title {
                font-size: 24px !important;
                font-weight: bold !important;
                color: $primaryDarkBlue !important;
                margin-bottom: 0.5em !important;
                word-break: break-all;
            }
            &__key {
                font-size: 18px;
                color: $primaryGray;
            }
            &__value {
                color: $primaryDarkBlue;
                font-size: 18px;
                word-break: break-all;
            }
        }
        &__btnWrap {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 100px;
        }
    }
}
.dialog-loader {
    position: fixed;
    top: 45%;
    left: 48%;
    background: $primaryWhite;
    display: flex;
    flex-direction: column;
}
.borderPrimaryInsurance {
    position: absolute;
    top: 51%;
    width: 64%;
    border-bottom: 1px solid $secondaryGray;
}
.upload-label {
    display: flex;
    padding: 1em 0.5em;
    background: $primaryDarkBlue;
    margin-top: 1em;
    width: fit-content;
    color: $primaryWhite;
    cursor: pointer;
    width: 146px;
    border-radius: 4px;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.mr2 {
    margin-right: 2em;
}
.editStaff-strip {
    background: $primaryDarkBlue;
    color: $primaryWhite;
    height: 100px;
    display: flex;
    align-items: center;
    padding: 1em;
    box-sizing: border-box;
}
.swal2-popup.swal2-modal.swal2-show {
    padding: 2em 0;
}
/* Provider Icons */

.provider-education-info svg,
.provider-education-info img {
    color: $primaryDarkGray;
}

/**  Add Clinic Admin **/
.edit-profile-view-wrapper .edit-view {
    margin: 0 3em;
}
