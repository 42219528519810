@import '../../../styles/themes/color.module.scss';

.modals-container {
    top: 0;
    left: 0;
    right: 0;
    width: 900px;
    border: none;
    bottom: 0;
    margin: auto;
    outline: none;
    padding: 0;
    position: absolute;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
    height: 80%;
    border-radius: 4px 4px 0 0;
    background-color: $primaryWhite;
    color: $primaryWhite;
    font-size: 18px;
    line-height: 29px;
    header {
        border-radius: 4px 4px 0 0;
        background-color: $primaryDarkBlue;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
    }
    body {
        height: 90%;
        overflow-y: auto;
        overflow-x: hidden;
    }
    footer {
        border-radius: 0 0 4px 4px;
        background-color: lightblue;
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
    }
    .show-footer {
        body {
            height: 80%;
        }
    }
    .modal-spacer {
        padding-left: 20px;
        padding-right: 20px;
    }
}
