@import '../../styles/themes/color.module.scss';
h3 {
    font-size: 22px;
    font-weight: bold;
}
ul.footer-links {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        padding: 10px 0;
        font-size: 15px;
        line-height: 20px;
        a {
            cursor: pointer;
            color: $primaryDarkBlue;
            &:hover {
                color: $secondaryBlue;
            }
            img {
                display: flex;
                max-width: 115px;
            }
        }
        button.blue-btn {
            color: $primaryWhite;
            font-size: 17px;
            line-height: 17px;
            margin-top: 0;
        }
    }
}
.footer-meta {
    display: flex;
    margin-top: 50px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    ul {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        padding: 0 0 0 25px;
        li {
            margin-right: 10px;
            a {
                padding-right: 12px;
            }
        }
    }
    .footer-meta-text {
        display: inline-block;
        padding-top: 5px;
        padding-bottom: 5px;
        color: $primaryDarkGray;
        font-size: 15px;
        line-height: 22px;
    }
    .footer-meta-link {
        padding-top: 6px;
        padding-bottom: 6px;
        border-bottom-style: none;
        color: $primaryDarkBlue;
        font-size: 15px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 582px) {
    .MuiContainer-root {
        &.footer-wrapper {
            display: none;
        }
    }
}
