.patientdetails-main-container {
    border-radius: 5px;
    border: 1px solid #bfbdbf;
    padding-left: 17px;
    padding-top: 15px;
    background: #fff;
    display: flex;
    flex-direction: column;
    // width: 389px;
    height: 100%;
    justify-content: space-between;
    padding-bottom: 10px;
}

.header-txt {
    color: #303e4e;
    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.content-main-1 {
    display: flex;
    flex-direction: column;
}

.content-main {
    display: flex;
}
.box-1 {
    display: flex;
    align-items: flex-start;
    width: 115px;
    height: 49px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
}

.box-2 {
    display: flex;
    align-items: flex-start;
    width: 92px;
    height: 49px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
}
.box-3 {
    display: flex;
    align-items: flex-start;
    width: 162.113px;
    height: 49px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
}
.box-4 {
    display: flex;
    align-items: flex-start;
    width: 131px;
    height: 49px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
}
.label {
    color: #667689;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.value {
    color: #303e4e;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}
