#show_sub{
    position: absolute;
    bottom: 120px;
    z-index: 999;
    left: 0;
    width: 100%;
    padding: 0 12px;
    text-align: center;
    .text-sub{
        font-size: 17px;
        color: #fff;
        background: #0000004e;
        line-height: 31px;
        padding: 5px;
    }
}