@import '../../styles/themes/color.module.scss';

.mark__icc__modal__container {
    min-height: 450px;
    height: 50%;
    width: 40%;
    background-color: $primaryWhite;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    outline: 0;
    padding: 14px;
    border-radius: 8px;
}

.mark__mcc__modal__container {
    height: 650px;
    width: 50%;
    background-color: $primaryWhite;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    outline: 0;
    padding: 14px;
    border-radius: 8px;
}

.mark__icc__modal__header {
    display: flex;
    flex-direction: row;
    margin: 16px;
    justify-content: center;
    align-items: center;
}

.mark__icc__modal__body {
    margin: 40px;
}

.mark__icc__modal__cancel-btn {
    height: 24px;
    width: 24px;
}

.mark__icc__modal__title {
    font-size: 32px;
}
