.video-call {
    align-self: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
}

.local-participant {
    .participant {
        background: white;
        // padding: 10px;
        border-radius: 6px;
        display: inline-block;
        margin-right: 10px;
        // position: relative;
        // right: 10px;
        // top: 10px;
        z-index: 99;
        cursor: move;
        transition: 1s all;
        position: absolute;
        bottom: 0;
        right: 0;

        .view-button-bar {
            width: 310px;
            background: rgba(255, 255, 255, 0.6);
            border-radius: 24px;
            margin-left: 110px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            gap: 10px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 24px;

            .button-bar {
                width: 50px;
                height: 50px;
                border-radius: 18px;
                border: none;
                background: rgba(255, 255, 255, 0.6);
            }

            .btn-end-call {
                background-color: #e81212;
            }
        }

        .view-button-bar.active {
            display: none;
        }

        .view-top-bar {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            gap: 8px;
            position: absolute;
            left: 430px;
            top: 24px;
            z-index: 999;
        }

        .view-top-bar.active {
            display: none;
        }
    }
}

.mini-size.active {
    transition: 1s all;
    display: block;
}

.hidden-video.active {
    display: none;
}

.video-participant {
    // width: 550px;
    height: 400px;
    max-width: 550px;
    display: block;
    margin: 0 auto;
    border-radius: 6px;
    float: left;
    object-fit: cover;
}

.video-participant.active {
    display: none;
}

.remote-participants {
    width: 120px;
    height: 90px;
    border: 1px solid #14ae5c;
    overflow: hidden;
    position: absolute;
    top: 20px;
    left: 24px;
    z-index: 9999;
    border-radius: 10px;
    background: transparent;
    padding: 0;

    .mini-size-icon {
        background: #14ae5c;
        position: absolute;
        width: 22px;
        height: 22px;
        bottom: 5px;
        right: 5px;
        text-align: center;
        border-radius: 10px;
        border: 2px solid #aee5c8;
        display: block;

        svg {
            margin-top: 4px;
        }
    }

    .participants-images {
        width: 120px;
        height: 120px;
        background-size: cover;
    }
}

.view-mini-size.active {
    background: #14ae5c;
    position: absolute;
    width: 22px;
    height: 22px;
    bottom: 10px;
    right: 0;
    text-align: center;
    border-radius: 10px;
    border: 2px solid #aee5c8;
    display: block;
    z-index: 9999;

    svg {
        margin-top: 4px;
    }
}

.view-mini-size {
    display: none;
}

.remote-participants.active {
    width: 120px;
    height: 120px;
    border: 2px solid #14ae5c;
    border-radius: 100%;
    top: 0;
    bottom: 0;
    left: 0;

    .mini-size-icon {
        display: none;
    }
}

.icon-svg-btn {
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 18px;
    border: none;
}

.icon-svg-btn-top {
    width: 30px;
    height: 30px;
    background: rgba(48, 62, 78, 0.8);
    backdrop-filter: blur(16px);
    border-radius: 10px;
    border: none;
}

.participant.active {
    width: 120px;
    height: 120px;
    // overflow: hidden;
    position: absolute;
    transform: translate(0vw, 0vh) !important;
    transition: 1s all;
    border-radius: 100%;
}

.participant.react-draggable-dragging {
    transition: unset;
}

/*component view participant*/
.view-list-consultation {
    //   display: flex;
    //   flex-direction: row;
    //   align-items: center;
    //   padding: 16px;
    //   gap: 35px;
    //   background: #f5f6f7;
    //   border-radius: 10px;
    //   margin: 20px 0;

    .infomation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0;
        gap: 4px;
        width: 100%;

        .name {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: #000;
        }

        .relationship {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            color: #9ca9b8;
        }
    }
}

// css video remote participnat

.videoRemoteParticipant {
    width: 120px;
    height: 90px;
    object-fit: cover;
}

.videoRemoteParticipant.active {
    width: 120px;
    height: 120px;
    object-fit: cover;
}

.localParticipant-minisize {
    display: none;
}

.localParticipant-minisize.active {
    display: block;
}

.pictures-avatar img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.listParticipant-remote {
    .video-participant {
        width: 120px;
        height: 120px;
    }
}

.remote-participants-list {
    width: 120px;
    height: 90px;
    border: 1px solid #14ae5c;
    overflow: hidden;
    position: absolute;
    top: 140px;
    left: 24px;
    z-index: 9999;
    border-radius: 10px;
    background: transparent;
    padding: 0;

    li {
        display: block;
        width: 120px;
        height: 90px;
        padding: 0;

        .video-participant {
            width: 120px;
            height: 90px;
            object-fit: cover;
        }

        .mini-size-icon {
            background: #14ae5c;
            position: absolute;
            width: 22px;
            height: 22px;
            bottom: 5px;
            right: 5px;
            text-align: center;
            border-radius: 10px;
            border: 2px solid #aee5c8;
            display: block;
            svg {
                margin-top: 4px;
            }
        }
    }
}

.list-participant-remote {
    display: flex;
    flex-direction: column;
    position: absolute;
    gap: 10px;
    left: 22px;
    top: 120px;
    .remote-participants-list {
        position: static;
    }
}
