.main-heath-account-container {
    border-radius: 5px;
    border: 1px solid #bfbdbf;
    background: #fff;
    // height: 100%;
    padding-left: 21px;
    padding-top: 23px;
    padding-right: 20px;
    // height: 562px;
    height: 95.5%;
}
.main-data-card-container {
    border-radius: 8px;
    border: 0.8px solid #bfbdbf;
    width: 227.203px;
    height: 92px;
    background: #fff;
    display: flex;
    // justify-content: center;
    align-items: center;
    // padding-top: 26px;
    // padding-bottom: 28px;
    padding-left: 13px;
    cursor: pointer;
}

.label-container {
    display: flex;
    flex-direction: column;
    margin-left: 2px;
}

.main-content {
    display: flex;
    align-items: flex-start;
}

.big-label {
    color: #303e4e;
    text-align: left;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 150% */
}
.small-label {
    color: #667689;
    text-align: center;
    font-family: Rubik;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 184.615% */
}
.card-wrapper {
    // overflow: auto;
    // position: absolute;
    margin-top: 12px;
    flex-wrap: wrap;
    display: flex;
    gap: 20px;
}
