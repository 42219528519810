@import '../../../styles/themes/color.module.scss';
@import '../../../styles/themes/sizing.scss';
.custom-button {
    display: flex;
    align-items: center;
    &.MuiButtonBase-root {
        // margin-top: 30px;
        height: 40px;
        min-width: 100px;
    }
}
.align-right {
    .MuiButton-label {
        flex-direction: row-reverse;
    }
}

.button-img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding: 8px;
}
.MuiButton-contained {
    &.space-10 {
        margin-top: $space-30;
    }
}
