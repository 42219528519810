@import '../../../styles/themes/color.module.scss';

.tab-view-container {
    display: inline-flex;
    margin: 0;
    padding: 0;

    .tab {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        height: 61.5px;
        background-color: $tertiaryGray;
        cursor: pointer;
        font-size: 15px;
        line-height: 18px;
        width: 150px;
        padding: 0 20px;
        margin-right: 2px;
        &:first-child {
            border-radius: 4px 0 0 4px;
        }
        &:last-child {
            border-radius: 0 4px 4px 0;
        }
        .tab-name {
            width: 110px;
            border-bottom: 2px solid $tertiaryGray;
            justify-content: center;
            display: flex;
            height: 61px;
            align-items: center;
            color: $primaryGray;
            font-weight: bold;
            transition: color 100ms ease-in-out 0s,
                font-weight 100ms ease-in-out 0s;
        }
        &.tab-active {
            background-color: $primaryWhite;
            box-shadow: 0 0 13px 0 $shadow;
            span {
                color: $primaryDarkBlue;
                border-bottom: 2px solid $primaryDarkBlue;
            }
        }
    }
}

.tab-content {
    min-height: 630px;
    border-radius: 4px;
    background-color: $primaryWhite;
    box-shadow: 0 0 13px 0 $shadow;
    padding: 26px 20px;
    .fax-from-txt {
        color: $primaryDarkBlue;
        cursor: pointer;
    }
}
