@import '../../styles/themes/color.module.scss';
.button-container {
    display: flex;
    justify-content: center;
    .btn-primary {
        font-weight: 500;
        width: 130px;
    }
}
.simple-modal-description {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
}
.modal-close {
    // display: flex;
    // justify-content: flex-end;
    position: absolute;
    top: 15%;
    right: 5%;
    cursor: pointer;
}
#modal {
    width: auto;
}
@media screen and (max-width: 628px) {
    #modal {
        width: 243px;
    }
}
