@import '../../../styles/themes/color.module.scss';

.worklist__modal__container {
    max-height: 72%;
    width: 30%;
    background-color: $primaryWhite;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    outline: 8px;
    border-radius: 8px;
}

.worklist__modal__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $primaryDarkBlue;
    color: $primaryWhite;
    padding: 16px 28px;
}

.worklist__modal__body {
    margin: 20px;
    flex: 1;
}

.worklist__modal__footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
}

.worklist__modal__title {
    flex: 8;
    font-size: 18px;
    color: $primaryWhite;
}

.worklist__modal__form-label {
    color: #333 !important;
    font-size: 18px !important;
}

.worklist__modal__form-field-container {
    margin-top: 10px;
}
.worklist__modal__cancel-btn {
    height: 32px;
    width: 32px;
    cursor: pointer;
}

.worklist__ba-choose-img-btn-filled {
    box-sizing: border-box;
    height: 41px;
    width: 146px;
    border: 1px solid $primaryDarkBlue;
    border-radius: 4px;
    cursor: pointer;
    color: $primaryWhite;
    background-color: $primaryDarkBlue;
    margin-left: 10px;
    margin-right: 10px;

    label {
        width: 133px;
        display: inline-block;
        height: 40px;
        padding-top: 10px;
        cursor: pointer;
    }
}

.worklist__ba-choose-img-btn-outline {
    box-sizing: border-box;
    border: 0;
    cursor: pointer;
    color: $primaryDarkBlue;
    background-color: $primaryWhite;
    font-weight: bold;
}

@media screen and (max-width: 650px) {
    .worklist__modal__container {
        max-height: 70vh;
        width: 90%;
    }

    .worklist__modal__body {
        margin: 20px;
        margin-top: 40px;
    }

    .worklist__modal__title {
        font-size: 20px;
    }

    .worklist__modal__cancel-btn {
        height: 24px;
        width: 24px;
    }
}
