@import '../../styles/themes/color.module.scss';
.app-layout {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */ 
        height: -webkit-fill-available;
    }
    .app-layout-container {
        // padding: 15px;
        height: calc(100vh - 70px);
        width: 100%;
        margin-top: 70px;
        background-color: $primaryWhite;
        position: relative;
        display: inline-flex;
        .app-layout-container-component-area {
            overflow-y: auto;
            overflow-x: hidden;
            width: 100%;
            background: white;
        }
    }

    @media only screen and (max-width: 1600px) {
        .app-layout-container
            .app-layout-container-component-area {
            max-block-size: fit-content;
        }
    }
    // @media only screen and (max-width: 600px) {
    //     .app-layout-container
    //         .app-layout-container-component-area {
    //         padding: 15px 10px;
    //     }
    // }
}
