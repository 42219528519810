@import '../../../styles/themes/color.module.scss';
.dropdown-container {
    min-width: 150px;
    box-sizing: border-box;
    // height: 50px;
    border: 1px solid $secondaryGray;
    border-radius: 4px;
    background-color: $primaryWhite;
    margin: 0 20px;
    .MuiFormControl-root {
        margin: 0 20px;
        min-width: 150px;
        background-color: $primaryWhite;
        border: 1px solid $secondaryGray;
        &:hover {
            border: 1px solid $secondaryGray;
        }
    }
    .MuiInputBase-root {
        // height: 50px;
        // min-width: 150px;
        .MuiSelect-select.MuiSelect-select {
            padding: 10.5px !important;
            color: $primaryGray;
            // font-size: 15px;
            font-weight: normal;
        }
        .MuiSelect-select:focus {
            background-color: white !important;
        }
        .MuiSelect-icon {
            right: 10px;
            color: $primaryGray;
            background: $primaryWhite;
        }
    }
}
