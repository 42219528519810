/** Brand Style Guide Color Variables **/
$primaryDarkBlue: #132044;
$primaryBlue: #5571c6;
$secondaryBlue: #2e519e;
$primaryDarkGray: #303e4e;
$primaryGray: #516983;
$primaryWhite: #fff;
$secondaryGray: #889db6;
$tertiaryGray: #d3def3;
$lightGray: #fbfbfb;
$textGray: #303e4e;
$bgGray: #f5f6f7;
$lightestGray: #f3f6fd;
$disabled: #b2c0cf;
$error: #ef767a;
$success: #4c8080;
$inProgress: #ffbc42;
$shadow: rgba(222, 231, 241, 0.5); /** remove **/
:export {
    primaryDarkBlue: $primaryDarkBlue;
    primaryBlue: $primaryBlue;
    secondaryBlue: $secondaryBlue;
    primaryDarkGray: $primaryDarkGray;
    primaryGray: $primaryGray;
    primaryWhite: $primaryWhite;
    secondaryGray: $secondaryGray;
    bgGray: $bgGray;
    textGray: $textGray;
    tertiaryGray: $tertiaryGray;
    lightGray: $lightGray;
    lightestGray: $lightestGray;
    disabled: $disabled;
    error: $error;
    success: $success;
    inProgress: $inProgress;
    shadow: $shadow; /** remove **/
}
