@import '../../../styles/themes/color.module.scss';
.profile-pic-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-wrap {
    :hover {
        cursor: pointer;
    }
}
.profile-pic-upload {
    width: 624px;
    height: auto;
    background-color: $primaryWhite;
    border-radius: 8px;
    header {
        width: 100%;
        background: none;
        display: flex;
        justify-content: flex-end;
        svg {
            color: $primaryDarkGray;
            margin-top: 22px;
            margin-right: 22px;
            height: 39px;
            width: 39px;
            :hover {
                cursor: pointer;
            }
        }
    }
    main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 0px;
        padding-top: 12px;
        padding-bottom: 56px;
        .placeholder {
            height: auto;
            min-height: 360px;
            width: 100%;
            background-color: #d8d8d8;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            .placeholder-text {
                height: 77px;
                width: 77px;
                color: $primaryDarkBlue;
                font-family: 'Rubik', sans-serif;
                font-size: 64px;
                letter-spacing: 0;
                line-height: 70px;
                text-align: center;
                margin-bottom: 0px;
            }
        }

        .placeholder-title {
            color: $primaryDarkBlue;
            font-family: 'Rubik', sans-serif;
            font-size: 24px;
            letter-spacing: 0;
            line-height: 26px;
            text-align: center;
            margin: auto;
            margin-top: 36px;
        }
        .upload-btn {
            background: $primaryDarkBlue !important;
            padding: 0.5em 2em !important;
            color: $primaryWhite;
            font-family: 'Rubik', sans-serif;
            font-size: 21px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 23px;
            text-align: center;
            justify-content: center;
            align-items: center;
            height: 40px;
            margin: auto;
            margin-top: 50px;
            &:disabled {
                background-color: rgba(
                    0,
                    0,
                    0,
                    0.12
                ) !important;
            }
        }
        .choose-new-delete-wrapper {
            padding-top: 50px;
            display: flex;
            column-gap: 170px;
            justify-content: center;
            font-family: 'Rubik', sans-serif;
            font-size: 21px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 23px;
            text-align: center;
            .choose-new-link {
                color: #506fcc;
                svg {
                    vertical-align: bottom;
                }
                &:hover {
                    cursor: pointer;
                }
            }
            .delete-link {
                color: #fc233b;
                svg {
                    vertical-align: bottom;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }

        input[type='file'] {
            display: none;
        }
        .modal-image {
            width: 80%;
            height: 80%;
            border-radius: 50%;
            overflow: hidden;
            box-shadow: 0px 0px 0px 30px
                rgba(216, 216, 216, 0.5);
        }
    }
}
