@import '../../../styles/themes/color.module.scss';
.custom_table {
    width: 100%;
    border-spacing: 0;
    border-radius: 4px;
    background: $primaryWhite;
    tr,
    th,
    td {
        height: 60px;
        max-height: 60px;
        border-bottom: 1px solid $tertiaryGray;
        border-right: 1px solid $tertiaryGray;
        padding: 0 10px;
    }
    th {
        padding: 0 10px;
        user-select: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        color: $primaryDarkBlue;
        background: rgba(243, 246, 253, 0.75);
    }
    tr {
        td:first-child,
        th:first-child {
            border-left: 1px solid $tertiaryGray;
        }
    }
    tr:first-child {
        th {
            border-top: 1px solid $tertiaryGray;
            &:first-child {
                border-top-left-radius: 4px;
            }
            &:last-child {
                border-top-right-radius: 4px;
            }
        }
    }
    tr:last-child {
        td:first-child {
            border-bottom-left-radius: 4px;
        }
        td:last-child {
            border-bottom-right-radius: 4px;
        }
    }
    &__cell {
        display: table-cell;
        color: $primaryGray;
        &-wrapper {
            display: inline-flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            flex-wrap: nowrap;
            flex-shrink: 0;
            flex-grow: 1;
        }
        &-sortable {
            cursor: pointer;
            .custom_table__cell {
                &-icon {
                    height: 24px;
                    width: 24px;
                    color: $primaryDarkGray;
                }
            }
        }
    }
    &__body {
        &__cell {
            white-space: nowrap;
            padding: 0 20px 0 20px;
            &-actions {
                white-space: nowrap;
                span {
                    margin-right: 14px;
                    height: 24px;
                    width: 24px;
                    cursor: pointer;
                    color: $primaryDarkBlue;
                    &:last-child {
                        margin-right: 0;
                    }
                    svg {
                        height: 24px;
                        width: 24px;
                        color: $primaryBlue;
                    }
                    &.listing_action_link {
                        width: auto;
                        &[disabled] {
                            background-color: unset !important;
                            color: #c2c5ce !important;
                            font-weight: bold;
                            cursor: not-allowed;
                            pointer-events: all !important;
                        }
                    }
                    &.cancelled_cell {
                        width: auto;
                        height: 18px;
                        width: 66px;
                        color: #e83e44 !important;
                        font-family: 'Rubik', sans-serif;
                        font-size: 15px;
                        letter-spacing: 0;
                        line-height: 18px;
                        cursor: not-allowed;
                        pointer-events: all !important;
                    }
                }
            }
            &-disabled {
                pointer-events: none;
                svg {
                    color: #ccc;
                }
            }
        }
    }
}
.div_container_appointment {
    display: inline-flex;
    padding: 10px;
}
