.clinical_note_main_container {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.clinical_note_sub_container {
    width: 1000px;
    height: 600px;
}

.clinical_note_back_container {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    margin-bottom: 5px;
    p {
        color: #303e4e !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 24px !important;
    }
}

.clinical_note_body {
    border-radius: 5px;
    border: 1px solid #bfbdbf;
    background: #fff;
    padding: 25px;
    height: 650px;
}

.clinical_note_body_header {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: 8px;
    border-bottom: 1px solid #eaecf0;
    p {
        color: #303e4e !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 22px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 24px !important;
    }
}

.clinical_notes_body_contents {
    padding: 15px 25px;
    display: flex;
    gap: 15px;
    flex-direction: column;
}

.clinical_notes_tab_contents {
    display: flex;
    width: 100%;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
}

.clinical_notes_tab_content_headers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .clinical_notes_tab_content_header {
        color: #303e4e !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 24px !important;
        text-transform: capitalize;
    }
    .clinical_notes_tab_content_sub_header {
        color: #667689 !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 15px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 24px !important;
    }
}

.clinical_notes_tab_content_count {
    display: flex;
    align-items: center;
    gap: 5px;
    p {
        color: #667689 !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 15px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 24px !important;
    }
}

.clinical_note_cards_loader {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 400px;
}

.clinical_notes_card_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 460px;
    padding-right: 10px;
    overflow-y: scroll;
}

.clinical_notes_cards {
    border-radius: 8px;
    border: 1px solid #e9ebed;
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.07);
    padding: 15px;
    width: 850px;
    display: flex;
    justify-content: space-between;
    p {
        text-transform: capitalize;
    }
}

.clinical_notes_cards_contents {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 7px;
}

.clinical_notes_card_type {
    display: flex;
    align-items: center;
    span {
        height: 25px;
        padding: 4px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        display: flex;
        border-radius: 5px;
        text-transform: capitalize;
    }
}

.clinical_notes_tab_content_third_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.clinical_note_tab_content_sorting {
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    cursor: pointer;
    p {
        color: #667689 !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 15px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 24px !important;
    }
}

.clinical_notes_card_type_loader {
    width: 100px;
}
