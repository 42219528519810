.dashboard_Home {
    padding: 40px !important;
}
.dashboard_Home_Struct {
    flex-wrap: wrap;
}
.number {
    color: #5571c6 !important;
    font-family: Rubik !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important;
}
.rightSide_Dashboard {
    .main-card {
        border-radius: 5px;
        border: 1px solid #bfbdbf;
        background: #fff;
        // height: calc(100vh - 220px) !important;
        height: 847px !important;
        margin-right: 100px !important;
        overflow: auto !important;
        margin-right: 70px !important;
        padding: 30px 0px 20px 30px !important;
        .mainText {
            color: #303e4e !important;
            font-family: Rubik !important;
            font-size: 22px !important;
            font-style: normal !important;
            font-weight: 500 !important;
            line-height: 24px !important;
        }
        .sub-mainText {
            color: #667689 !important;
            font-family: Rubik !important;
            font-size: 16px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 24px !important;
            margin-top: 10px !important;
        }
        .view_tab_parent .MuiTabs-root {
            min-height: 35px !important;
        }
        .view_tab_parent .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root {
            height: 35px !important;
            min-height: 35px !important;
        }
        .tabClasses {
            margin-top: 15px !important;
        }
        .no-of-appointment-text {
            margin-top: 15px !important;
            color: #667689 !important;
            font-family: Rubik !important;
            font-size: 16px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 24px !important;
        }
        .appointmentListingBox {
            height: 620px !important;
            overflow-y: auto !important;
        }
        .datagridbox {
            border-radius: 10px !important;
            border: 1px solid #d8d8d8 !important;
            background: #fbfdff !important;
            // height: 104px;
            padding: 20px 0px 20px 20px;
        }
        .year-month-text {
            color: #303e4e;
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }
        .drName {
            color: #303e4e;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
        .noteType {
            color: #667689;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
        .time {
            color: #303e4e;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            padding-left: 7px;
        }
        .confirmText {
            color: #303e4e;
            font-family: Rubik;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            padding-left: 7px;
        }
        .lunchVisitButton {
            display: flex;
            width: 130px;
            height: 35px;
            padding: 6px 12px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            border-radius: 5px;
            background: #15a452;
            color: #fbfdff;
            text-align: center;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }
    }
}
