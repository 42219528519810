@import '../../../styles/themes/color.module.scss';

.upload-btn-container {
    height: 40px !important;
    width: 137px !important;
    border-radius: 4px !important;
    background-color: $primaryWhite !important;
    border-radius: 4px !important;
    margin-right: 17px !important;
    margin-top: 19.5px !important;
    border: 1px solid $primaryDarkBlue !important;
    cursor: pointer !important;
    text-align: center;
    display: inline-block;

    span {
        height: 18px;
        width: 113px;
        color: $primaryDarkBlue;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
    }
}

/* Tab Styles */

.tab-view-container {
    display: inline-flex;
    margin: 0;
    padding: 0;
    .tab {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        height: 61.5px;
        background-color: $primaryWhite;
        cursor: pointer;
        font-size: 16px;
        line-height: 22px;
        width: 150px;
        padding: 0 0px;
        &:first-child {
            border-radius: 4px 0 0 4px;
        }
        &:last-child {
            border-radius: 0 4px 4px 0;
        }
        .tab-name {
            width: 150px;
            border-bottom: 2px solid $primaryWhite;
            justify-content: center;
            display: flex;
            height: 61px;
            align-items: center;
            color: $primaryGray;
            font-weight: bold;
            transition: color 100ms ease-in-out 0s,
                font-weight 100ms ease-in-out 0s;
        }
        &.tab-active {
            background-color: $primaryWhite;
            box-shadow: 0 0 13px 0 $shadow;
            span {
                color: $primaryBlue;
                border-bottom: 2px solid $primaryBlue;
            }
        }
    }
}

.search-field-box {
    height: 46px !important;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $primaryGray;
    padding: 0 10px;

    svg {
        color: $primaryGray;
        margin-right: 10px;
    }
}

.custom-btn {
    min-width: 150px !important;
    border-radius: 8px !important;
    // background-color: $primaryDarkBlue !important;
    // border-radius: 4px !important;
    // border: 1px solid $primaryDarkBlue !important;

    cursor: pointer !important;

    span {
        min-width: 113px !important;
        // color: $primaryWhite !important;
        font-size: 15px !important;
        font-weight: normal !important;
        letter-spacing: 0 !important;
        line-height: 50px !important;
        text-align: center !important;
    }
}

.custom-input {
    border-radius: 8px !important;
}
.white {
    background-color: $primaryWhite !important;

    span {
        color: #303e4e !important;
    }
}

.custom-btn:disabled,
.custom-btn[disabled] {
    border: 1px solid $primaryGray !important;
    background-color: $primaryGray !important;
    span {
        color: $primaryWhite !important;
    }
}

.custom-swal {
    height: 350px;
}

.swal-btn {
    box-sizing: border-box;
    height: 41px;
    width: 151px;
    border-radius: 4px !important;
}

.swal2-close {
    color: $primaryDarkBlue;
    height: 60px;
    width: 60px;
    font-size: 60px;
}

.swal2-close:hover {
    color: $primaryDarkBlue;
}

.custom-swal .swal2-cancel,
.custom-swal .swal2-deny {
    box-sizing: border-box;
    height: 41px;
    width: 151px;
    border-radius: 4px !important;
    border: 1px solid $primaryWhite !important;
    background-color: $primaryDarkBlue !important;
    color: $primaryWhite !important;
}

.custom-swal .swal2-confirm {
    box-sizing: border-box;
    height: 41px;
    width: 151px;
    border-radius: 4px !important;
    border: 1px solid $primaryDarkBlue !important;
    background-color: $primaryWhite !important;
    color: $primaryDarkBlue !important;
}

.swal2-html-container {
    display: flex !important;
    align-items: flex-end;
    color: $primaryGray;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
    text-align: center;
    margin: 0;
}
.swal2-actions {
    margin: 0 auto 0;
}
.swal2-html-container {
    margin: 40px 20px;
    font-size: 24px;
    line-height: 38px;
}
.swal2-actions button {
    margin-bottom: 20px;
}
