@import '../../styles/themes/color.module.scss';

.worklist {
    width: 100%;
    &__header {
        display: flex;
        flex-direction: row;
        height: 60px;
        align-items: center;
        margin-bottom: 20px;
        &-label {
            flex: 1;
            font-size: 18px;
            padding: 0 0 0 10px;
            font-weight: bold;
            display: flex;
            height: 100%;
            align-items: center;
            color: $primaryDarkBlue;
        }
        &-dropdown-wrapper {
            flex: 5;
            display: flex;
            height: 125%;
            align-items: center;
            &-control {
                width: 130%;
                background-color: $primaryWhite !important;
                margin: 0px 10px !important;
            }
        }
        &-dropdown-container {
            width: 100%;
            height: 54px;
            border: 1px solid $secondaryGray !important;
            border-radius: 4px;
            background-color: $primaryWhite !important;
            .MuiInputBase-root {
                height: 54px;
            }
        }
        &-clear-btn {
            font-size: 16px;
            color: $primaryDarkBlue;
            cursor: pointer;
            margin-left: 10px;
        }
        &-back_button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-right: 20px;
            &-icon {
                height: 24px;
                width: 24px;
                margin-right: 4px;
            }
            &-label {
                font-size: 18px;
                font-weight: bold;
                color: $primaryDarkBlue;
            }
        }
    }
}

.cardlist {
    padding: 10px;
    &-card__header {
        margin-top: -74px;
    }
    &-card__icon {
        width: 25px;
        margin-top: 25px;
        float: left;
        margin-right: 10px;
    }
    &__crd__label {
        float: left;
        width: 100px;
        font-size: 18px;
        margin-top: 18px;
    }
    &-dropdown-wrapper {
        &-control {
            background-color: $primaryWhite !important;
            margin: 0px 10px !important;
        }
    }
    &-dropdown-container {
        width: 141px;
        float: right;
        height: 35px;
        border: 1px solid $secondaryGray !important;
        border-radius: 4px;
        background-color: $primaryWhite !important;
        margin: 10px 10px !important;
        .MuiInputBase-root {
            height: 54px;
        }
    }
    &-detail__view {
        color: $primaryDarkBlue;
        cursor: pointer;
        margin-top: 19px;
        float: right;
        padding-right: 10px;
    }
}
