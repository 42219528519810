@import '../../../styles/themes/color.module.scss';
.intake-modal-container {
    width: calc(100% - 600px);
    height: calc(100% - 125px);
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 15px;
    overflow-y: scroll;
    .popup-close-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .head-line {
            color: #303e4e;
            font-size: 18px;
            font-weight: 500;
        }
        svg {
            font-size: 20px;
            color: #667689;
            cursor: pointer;
        }
    }
    .modal-divider {
        background: #bfbfbf;
        height: 1px;
    }
    .modal-container {
        max-height: 610px;
        overflow-y: scroll;
    }
    .modal-content {
        padding-top: 0 !important;
        p {
            text-align: justify;
        }
    }
    .terms-modal {
        display: flex;
        grid-gap: 10px;
        gap: 10px;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 10px;
        .MuiFormControlLabel-root {
            margin: 0px !important;
            .MuiButtonBase-root {
                padding: 0px !important;
            }
        }
        .modal-footer-content {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
}
