/*** Mixins for Media Queries ***/

@mixin mediaQuery($size) {
    @media only screen and (max-width: $size) {
        @content;
    }
}

/** Application **/

.container {
    @include mediaQuery($mobile-small) {
        font-size: 14px;
    }
}

/*** Colors ***/

@mixin colorSelector($color) {
    color: $color;
}

/** Application **/
.primary-color {
    @include colorSelector(
        $primaryDarkBlue
    ); /** Usage of Color Variables defined in color scss **/
}

/**
 * TRUNCATE - trunc
 * 
 * For use in single-line truncation
 */
.ms-trunc {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
