@import '../../../styles/themes/color.module.scss';
.search_input {
    height: 40px;
    border: 1px solid $secondaryGray;
    min-width: 60%;
    border-radius: 4px;
    background-color: $primaryWhite;
    margin: 0 20px;
    display: inline-flex;
    align-items: center;
    padding: 0 10px;
    &:focus,
    &:focus-within {
        box-shadow: 0px 0px 2px 0px rgb(85 113 198 / 50%);
    }
    svg {
        color: $primaryGray;
        margin-right: 10px;
        width: 16px;
        height: 16px;
    }
    .MuiInputBase-root {
        width: 100%;
        height: 40px;
    }
    .MuiInputBase-input {
        font-size: 14px;
        line-height: 24px;
        color: $primaryGray;
    }
}
