.app_appointmentdeatis_header {
    border-radius: 3px;
    border: 1px solid #101c3b;
    background: #134080;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    // height: 68px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 12px 20px !important;
    // position: absolute;
    // width: 100%;
    // top: 75px;
    z-index: 2;
    .appointmentTextStart {
        color: #fff;
        font-family: Rubik;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        align-items: baseline;
    }
    .appointmentText {
        margin-left: 5px;
        color: #fff;
        font-family: Rubik;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        margin-right: 5px;
        align-items: baseline;
    }
    .launchButton {
        margin-left: 15px;
        border-radius: 5px;
        font-family: Rubik;
        background: #15a452;
        // display: flex;
        // width: 113px;
        height: 35px;
        padding: 6px 12px;
        // justify-content: center;
        // align-items: center;
        flex-shrink: 0;
        color: #fff;
        // text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-transform: none;
    }
}
.book-now {
    text-decoration: underline;
    cursor: pointer;
}
.book-now:hover {
    text-decoration: none;
}

@media (max-width: 600px) {
    .app_appointmentdeatis_header {
        // height: 50px;
        .appointmentTextStart {
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
        }
        .appointmentText {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
        }
        .launchButton {
            margin-left: 30px;
            margin-right: 10px !important;
            height: 30px;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            text-transform: none;
        }
    }
}
