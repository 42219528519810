.header-top-main {
    // border: 2px solid black;
    // position: relative;
    gap: 49px;
    flex-wrap: wrap;
}
.header-top-left-sub {
    // border: 2px solid rgb(173, 63, 63);
    padding-top: 10px;
    flex-grow: 1;
}
.header-top-left-main {
    // width: 899px;
    height: 187px;
    border-radius: 5px;
    border: 1px solid #bfbdbf;
    background: #fff;
    display: flex;
    margin-bottom: 19px;
}
.header-top-right-main {
    border-radius: 5px;
    // border: 1px solid #BFBDBF;
    background: #fff;
    // margin-left: 100px !important;
}
.profilepic {
    fill: #fafafa;
    stroke-width: 1px;
    stroke: #d8d8d8;
    margin-top: 63px;
    margin-left: 16px;
    margin-bottom: 58px;
    // border-radius: 37px;
    // border: 1px solid #d8d8d8;
}
.profile-details {
    // border: 1px solid rgb(231, 11, 96);
    margin-left: 22px;
    margin-top: 23px;
    display: flex;
    flex-direction: column;
}
.mainName {
    color: #303e4e;
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 365.726px;
    height: 49px;
    display: flex;
    align-items: center;
}

.othersdetails {
    color: #303e4e;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
    display: flex;
    align-items: center;
}

.edit_icon {
    margin-top: 20px;
    margin-left: 35px;
    border: 0.3px solid #5571c6;
    stroke-width: 0.3px;
    border-radius: 10px;
    position: absolute;
    right: 304px;
    top: 67px;
    display: flex;
    background: #eef6fe;
}
