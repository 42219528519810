@import '../../styles/themes/color.module.scss';
@import '../../styles/themes/sizing.scss';
.container {
    max-width: 1220px;
    padding: 4rem;
    .back-arrow {
        cursor: pointer;
    }
    .form-wrapper {
        background: $primaryWhite;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        .back-arrow {
            cursor: pointer;
        }
        .main-header {
            margin-bottom: 2%;
        }
        .contact-us {
            display: flex;
            justify-content: center;
            margin-top: 10px;
            font-size: 14px;
            a {
                margin-left: $space-5;
                cursor: pointer;
            }
        }
        .center-align {
            display: flex;
            justify-content: center;
        }
        .align-item-center {
            display: flex;
            align-items: center;
        }
        .link-blue {
            cursor: pointer;
        }
        .form {
            margin: 2rem 6rem;
            .input-label {
                font-size: 14px;
                padding-bottom: 8px;
            }
            .text-input {
                width: 100%;
                color: #777777;
                margin-bottom: 2rem;
                .MuiFormHelperText-root {
                    color: $primaryDarkBlue;
                    .flex-center {
                        display: flex;
                        justify-content: center;
                        margin: 20px 0 0px;
                    }
                }
            }
            .submit {
                display: flex;
                flex-direction: column;
                align-items: center;
                .btn-primary {
                    font-weight: 500;
                    color: $primaryWhite !important;
                    text-transform: capitalize !important;
                    width: 130px;
                }
                h5 {
                    color: #777777;
                    margin-top: 12px;
                }
            }
            .btn-primary {
                font-weight: 700;
                color: $primaryWhite !important;
                text-transform: capitalize !important;
                width: 130px;
            }
            .error-login {
                color: #777777;
                text-align: center;
                margin-top: 94px;
                a {
                    color: $primaryDarkBlue;
                    margin-left: $space-5;
                }
            }
            .m-container {
                display: flex;
                margin-bottom: 1rem;
            }
            .r-container {
                display: flex;
                align-items: center;
            }
            .loader {
                display: flex;
                justify-content: 'flex-start';
                margin-bottom: 1rem;
            }
            .ba-back-icon {
                margin-left: -7px;
            }
        }
    }
}

.inner-wrapper-logo {
    display: none;
}

@media screen and (max-width: 1024px) {
    .container {
        padding: 2rem;
        .form-wrapper {
            form.form {
                margin: 2rem 2rem;
            }
            .inner-wrapper-logo {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 20px 0;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .container {
        .form-wrapper {
            margin: 0;
            .form {
                padding: 10px;
                .error-login {
                    margin-top: 10px;
                }
                .input-label {
                    font-size: 14px;
                    padding-bottom: 8px;
                }
            }
            .inner-wrapper-logo {
                img {
                    max-width: 150px;
                }
            }
        }
    }
}
@media screen and (max-width: 582px) {
    .container {
        .form-wrapper {
            .inner-wrapper-logo {
                margin: 20px 0 30px;
                img {
                    max-width: 100px;
                }
            }
        }
    }
}
@media only screen and (max-width: 375px) {
    .container {
        .form-wrapper form.form {
            padding: 0 1rem;
        }
    }
}
@media only screen and (max-width: 360px) {
    .container {
        padding: 0 1rem 1rem;
        .form-wrapper form.form {
            padding: 0;
        }
        .form-wrapper .form {
            .submit {
                padding: 15px 25px !important;
            }
            .error-login {
                margin-top: 10px !important;
            }
        }
    }
}
