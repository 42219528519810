input {
    // border-radius: 4; Incorrect scss
    position: relative;
    // border: "1px solid #ced4da"; Incorrect scss
    // font-size: 16; Incorrect scss
    // padding: "10px 26px 10px 12px"; Incorrect scss
}
.MuiAutocomplete-inputRoot {
    padding: 0 0 0 9px !important;
}
.form-img {
    height: 100%;
    width: 100%;
}
.captcha-error {
    justify-content: flex-start !important;
}
.select-gray:disabled {
    color: gray !important;
}
#mui-component-select-securityQestion2 {
    color: gray;
}
#mui-component-select-securityQestion1 {
    color: gray;
}
#mui-component-select-securityQestion3 {
    color: gray;
}
.term-check {
    margin-right: 0 !important;
}
.term-condition {
    font-size: 14px !important;
    a {
        cursor: pointer;
    }
}
.form.patientAccount {
    .rc-anchor-pt {
        display: none;
    }
}

ul
    li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
    overflow-x: auto;
}

@media only screen and (max-width: 582px) {
    .form-img {
        display: none;
    }
}
@media only screen and (max-width: 375px) {
    .form.patientAccount {
        div#g-recaptcha {
            position: relative;
            left: -25px;
            top: 20px;
        }
    }
}
