.message_care_team_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    flex-direction: column;
    gap: 40px;
    p {
        color: #667689 !important;
        font-family: Rubik !important;
        font-size: 40px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: normal !important;
        text-shadow: 1px 2px 10px #a8afb8;
    }
}

.mct_back_container {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    margin-bottom: 5px;
    p {
        color: #303e4e !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 24px !important;
    }
}
