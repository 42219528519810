.app_header {
    background-color: #fff;
    display: flex;
    height: 70px;
    min-height: 70px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    z-index: 2;
    .tabText {
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .hello {
        color: #989ca8;
        text-align: right;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
    .patientNameText {
        padding-left: 5px;
        padding-right: 5px;
        color: var(--1-c-2-a-54, #1c2a54);
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        height: 22px;
    }
    .user_image {
        height: 40px;
        width: 40px;
        border-radius: 50%;
    }
}
