@import '../../styles/themes/color.module.scss';
.user_container {
    padding: 20px;
    height: calc(100vh - 110px);
    &__header {
        display: inline-flex;
        width: 100%;
        height: 40px;
        align-items: center;
        justify-content: space-between;
        &-wrapper {
            display: inline-flex;
            align-items: center;
        }
        &-label {
            font-size: 18px;
            padding: 0 0 0 10px;
            font-weight: bold;
            display: flex;
            height: 100%;
            align-items: center;
            color: $primaryDarkBlue;
        }
        &__actions {
            display: inline-flex;
            flex-wrap: nowrap;
            white-space: nowrap;
            min-width: 70%;
            justify-content: flex-end;
            .dropdown-container {
                border: 1px solid $secondaryGray;
                .MuiInputBase-root {
                    min-width: 150px;
                }
            }
        }
        &__action {
            display: inline-flex;
            flex-wrap: nowrap;
            white-space: nowrap;
            min-width: 68%;
            justify-content: flex-end;
        }
        &-back_button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-right: 20px;
            &-icon {
                height: 24px;
                width: 24px;
                margin-right: 4px;
            }
            &-label {
                font-size: 18px;
                font-weight: bold;
                color: $primaryDarkBlue;
            }
        }
    }
    &__body {
        margin-top: 20px;
        &__pagination {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            .MuiPaginationItem-page.Mui-selected {
                background: $primaryDarkBlue;
                color: $primaryWhite;
            }
        }
    }
}

.mt1 {
    overflow: auto;
    height: calc(100vh - 280px);
}
