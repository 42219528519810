@import '../../styles/themes/color.module.scss';
.rbc-date-cell {
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    margin-top: 8px;
    margin-left: 6px;
    margin-bottom: 8px;
    font-weight: bold;
    text-align: left;
    line-height: 24px;
}

.timeoff-dropdown {
    border: 1px solid #c7c6c6 !important;
    border-radius: 4px;
    background-color: $primaryWhite !important;
}

.example {
    font-size: 14px;
    // padding: 0 40px;
    min-height: calc(100vh - 100px);
    min-height: max-content;
    height: calc(100vh - 100px);
    width: 100%;
    margin: auto;

    &,
    & > * {
        display: flex;
        flex-direction: column;
    }

    .rbc-calendar {
        flex: 1;
        min-height: 880px;
        .MuiGrid-container {
            align-items: center;
        }
        .mon_year_wrapper {
            margin-right: 10px;
        }
        .typography18 {
            font-size: 18px;
            line-height: 24px;
            font-weight: bold;
            color: $primaryDarkBlue;
        }
        .rbc-month-view,
        .rbc-time-view {
            border-color: $secondaryGray;
        }
        .rbc-month-view {
            .rbc-month-row + .rbc-month-row {
                border-top: none;
            }
        }
        .rbc-header {
            border-color: $secondaryGray;
            // border-left: 1px solid $secondaryGray;
            .MuiGrid-container {
                align-items: flex-start;
            }
        }
        .rbc-header + .rbc-header {
            border-color: $secondaryGray;
        }
        .rbc-day-bg + .rbc-day-bg {
            border-color: $secondaryGray !important;
            // border-left: none !important;
        }
        .rbc-row-bg {
            border-bottom: 1px solid $secondaryGray !important;
        }
        // .rbc-off-range {
        //     color: $primaryDarkBlue;
        //     opacity: 30%;
        //     background: #e3e3e3;
        // }
        .rbc-row .rbc-date-cell a {
            color: $primaryDarkBlue;
        }
        .rbc-event-content p b {
            color: $primaryDarkBlue;
            font-size: 15px;
            line-height: 17px;
        }
        .time-off-wrap p b {
            color: $primaryDarkBlue;
        }
        .calendar_select_wrap {
            justify-content: flex-start;
            .MuiOutlinedInput-root {
                height: 40px;
                border-radius: 6px !important;
                // .MuiSelect-outlined.MuiSelect-outlined {
                //     font-size: 18px;
                //     line-height: 21px;
                //     font-weight: bold;
                //     border-radius: 4px;
                //     &.MuiInput-underline:before {
                //         border-bottom: none;
                //     }
                // }
            }
            .MuiInputBase-root.MuiOutlinedInput-root.select_dd {
                width: 150px;
            }
        }
        .mon_year_wrapper {
            button.MuiButtonBase-root.MuiIconButton-root {
                border: 1px solid $secondaryGray;
                border-radius: 0;
                height: 35px;
                width: 35px;
                cursor: pointer;
                justify-content: center;
                align-items: center;
            }
            .date_wrap {
                font-size: 18px;
                line-height: 21px;
                color: $primaryDarkBlue;
                border: 1px solid $secondaryGray;
                height: 35px;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 10px;
                // margin: 0 10px;
                min-width: 110px;
            }
            .iconStretch {
                justify-content: stretch;
                svg {
                    left: 5px;
                    position: relative;
                }
            }
            .icon_next {
                margin-left: 5px;
            }
            svg.MuiSvgIcon-root {
                color: $primaryDarkBlue;
                font-size: 16px;
                font-weight: bold;
            }
            button {
                margin: 0;
                padding: 0;
                border-radius: 0;
            }
        }
        /* Month view header weekdays */
        .rbc-month-header {
            .rbc-header {
                height: 49px;
                align-items: center;
                justify-content: flex-start;
                display: flex;
                padding: 0;
                position: relative;
                .MuiBox-root {
                    font-size: 18px;
                    line-height: 24px;
                    color: $primaryDarkBlue;
                    padding: 0 6px;
                }
            }
        }
        /** timeoff spacers **/
        .rbc-month-row {
            .rbc-row {
                .rbc-event {
                    min-height: 29px;
                }
            }
        }
        .rbc-event-content .time-off-wrap {
            padding: 4px 6px;
        }

        /* Week view Header weekdays with day */

        .rbc-time-view {
            .rbc-time-header.rbc-overflowing {
                align-items: center;
                justify-content: flex-start;
                display: flex;
                padding: 0;
                position: relative;
                .MuiBox-root {
                    font-size: 14px;
                    line-height: 24px;
                    color: $primaryDarkBlue;
                    padding: 0 20px;
                }
                .date {
                    font-size: 21px;
                }
            }
        }

        .event-wrap {
            padding: 4px;
            &.time-off {
                background: #e3e3e3;
                justify-content: center;
                text-align: center;
                .MuiTypography-body1,
                b {
                    color: $primaryDarkBlue;
                }
            }
            .slotType {
                position: relative;
                right: 5px;
                span {
                    width: 30px;
                    height: 30px;
                    background: #dce2fd;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $primaryDarkBlue;
                }
            }
            .time {
                padding-left: 2px;
            }
        }
        .rbc-event {
            padding: 0;
        }
        .currentDay {
            .MuiBox-root {
                width: 100%;
                height: 10px;
                padding: 0px;
                position: absolute;
                bottom: 0px;
                width: 100%;
                left: 0;
                border-bottom: 8px solid $primaryDarkBlue;
                background-color: transparent;
            }
        }
        .btn-outline {
            border: 1px solid $primaryDarkBlue;
            width: 96px;
            height: 40px;
            font-size: 15px;
            line-height: 17px;
            font-weight: bold;
            color: $primaryDarkBlue;
            background: $primaryWhite;
        }
        //    .rbc-row-bg :not(first-child) {
        //     border-right: 1px solid $secondaryGray;
        //     }

        /* WEEK VIEW STYLES */

        /* Event colors */
        .rbc-day-slot .rbc-events-container {
            margin-right: 0;
            .rbc-event {
                border: none;
                border-radius: 4px;
                width: 100%;
                .rbc-event-content {
                    // background: #b3e5fc;
                    // background: #d1c4e9;
                }
            }
        }

        .week-view {
            padding: 5px;
            color: $primaryDarkBlue;
        }
        .rbc-time-header-content {
            .rbc-header {
                height: 70px;
                align-items: center;
                display: flex;
                .MuiGrid-item {
                    .MuiTypography-body1 {
                        font-size: 14px;
                        line-height: 24px;
                        color: $primaryDarkBlue;
                        // font-weight:bold;
                        &.font-small {
                            font-size: 14px;
                            line-height: 24px;
                        }
                        &.font-medium {
                            font-size: 21px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
        .rbc-time-content > * + * > * {
            border-left: 1px solid $secondaryGray;
        }
        /*** TIME SLOT ***/
        .rbc-time-content {
            /** Week slot details **/
            .rbc-day-slot .rbc-event-content {
                padding: 3px 10px;
                display: flex;
                span.week-time-slot {
                    width: 20px;
                    height: 20px;
                    background: #dce2fd;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $primaryDarkBlue;
                    margin-left: 5px;
                }
            }
            .rbc-event-content {
                padding: 10px 10px;
                color: $primaryDarkBlue;
                font-size: 14px;
                font-weight: bold;
            }
        }
        /*** Hide the time slot header **/
        .rbc-time-view {
            .rbc-allday-cell {
                display: none;
            }
            .rbc-time-content {
                border-top: none;
            }
        }
        .rbc-header.rbc-today {
            position: relative;
            // border-bottom: 6px solid $primaryDarkBlue;
        }
    }
    .time-zone {
        min-width: 222px;
        margin-right: 10px;
        color: $primaryDarkBlue;
        font-size: 15px;
        line-height: 24px;
    }
}

.rbc-date-cell .appointmentCount {
    width: 30px;
    height: 30px;
    background: #dce2fd;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primaryDarkBlue;
    margin-left: 9px;
}

/* Modal Styles */
.calendarModal {
    header {
        border-radius: 4px 4px 0 0;
        background-color: $primaryDarkBlue;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        span {
            color: $primaryWhite;
            font-size: 18px;
            line-height: 29px;
        }
    }
    main {
        padding: 20px;
        .details-wrap {
            border-radius: 4px;
            min-height: 36px;
            display: flex;
            align-items: center;
            padding: 10px 15px;
            margin-bottom: 20px;
            .bar {
                border-right: 2px solid $primaryDarkBlue;
                margin-right: 10px;
            }
            .time {
                font-size: 15px;
                line-height: 18px;
                font-weight: bold;
            }
            .field1 {
                font-size: 12px;
                padding-right: 10px;
                border-right: 1px solid #111;
                margin-right: 10px;
                line-height: 13px;
            }
            .tele-field,
            .clinic-field {
                font-size: 12px;
                line-height: 13px;
            }
            .clinic-field {
                font-weight: bold;
            }
        }
    }
}

/** TimeOff Centered **/
.MuiGrid-root.week-time-off {
    position: relative;
    top: 50%;
}
.rbc-time-view {
    .week-view-time {
        font-size: 13px;
    }
    .font13 {
        font-size: 13px;
    }
}

/** Day View - To increase the height of the cell **/
.rbc-time-column .rbc-timeslot-group {
    min-height: 100px;
}

.day-event-wrap {
    span.time {
        padding-right: 10px;
        border-right: 2px solid #111;
        margin-right: 10px;
    }
    span.title {
        padding: 1px 10px 1px 0;
        border-right: 1px solid #111;
        margin-right: 10px;
        line-height: 15px;
        font-size: 13px;
    }
    .appointment-type {
        line-height: 15px;
        font-size: 13px;
    }
}

/**** ****/

.tooltip-wrapper {
    color: $primaryDarkBlue;
    .date {
        font-size: 18px;
        line-height: 27px;
        font-weight: bold;
        border-right: 1px solid #111;
        padding-right: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
    }
    .time {
        font-size: 18px;
        line-height: 27px;
        font-weight: bold;
    }
    .gray {
        color: $primaryGray;
        font-size: 15px;
        line-height: 34px;
        margin-right: 5px;
    }
}

.req-time-off-btn.MuiButton-containedPrimary {
    margin-left: 10px;
}
.MuiInputBase-root.MuiOutlinedInput-root.select_dd.fullWidth {
    width: 100% !important;
}
@media only screen and (max-width: 1550px) {
    .rbc-date-cell .appointmentCount {
        margin-left: 5px;
    }
}

@media only screen and (max-width: 1490px) {
    .example {
        .rbc-calendar .btn-outline {
            width: 65px;
            height: 35px;
            .typography18 {
                font-size: 15px;
            }
        }
        .rbc-calendar .calendar_select_wrap {
            .MuiInputBase-root.MuiOutlinedInput-root.select_dd {
                width: 80px;
            }
        }
        .req-time-off-btn.MuiButton-containedPrimary {
            margin-left: 7px;
            padding: 6px 6px;
        }
    }
}

@media only screen and (max-width: 1360px) {
    .rbc-date-cell .appointmentCount {
        margin-left: 3px;
    }
    .example .rbc-calendar {
        .rbc-event-content p b {
            font-size: 14px;
        }
        .mon_year_wrapper .date_wrap,
        .typography18 {
            font-size: 16px;
        }
    }
}
@media only screen and (max-width: 1280px) {
    .example .rbc-calendar {
        .rbc-event-content p b {
            font-size: 13px;
        }
        .rbc-event-content .time-off-wrap {
            font-size: 13px;
        }
        .rbc-date-cell .appointmentCount {
            width: 25px;
            height: 25px;
            font-size: 13px;
        }
        .rbc-row .rbc-date-cell a {
            font-size: 15px;
        }
        .main-wrapper {
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;
            width: 100%;
            .date-wrapper,
            .left-wrapper {
                max-width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 1180px) {
    .example .rbc-calendar .event-wrap {
        .time {
            padding-left: 0;
        }
        .slotType span {
            width: 20px;
            height: 20px;
            font-size: 13px;
            margin-left: 2px;
        }
    }
    .rbc-date-cell .appointmentCount {
        width: 20px;
        height: 20px;
        font-size: 13px;
        margin-left: 2px;
    }
}

@media only screen and (max-width: 1024px) {
    .example .rbc-calendar {
        .typography18 {
            font-size: 14px;
        }
        .mon_year_wrapper .date_wrap {
            font-size: 14px;
        }
        .mon_year_wrapper {
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .example
            .rbc-calendar
            .calendar_select_wrap
            .MuiOutlinedInput-root {
            height: 30px;
            p.MuiTypography-root {
                font-size: 14px;
            }
        }
        .calendar_select_wrap
            // .MuiOutlinedInput-root.MuiInputBase-fullWidth
        //     .MuiSelect-outlined.MuiSelect-outlined {
        //     font-size: 14px;
        // }
        .btn-outline {
            width: auto;
            padding: 5px 10px;
        }
        .MuiGrid-spacing-xs-2 > .MuiGrid-item {
            padding: 0 8px 8px;
        }

        .rbc-month-header .rbc-header .MuiBox-root {
            font-size: 14px;
        }
        .currentDay .MuiBox-root {
            border-bottom: 6px solid $primaryDarkBlue;
        }
        .rbc-time-header-content .rbc-header {
            height: 65px;
            align-items: flex-start;
            .MuiGrid-item {
                .MuiTypography-body1 {
                    &.font-medium {
                        font-size: 18px;
                        line-height: 24px;
                    }
                    &.font-small {
                        font-size: 13px;
                        line-height: 19px;
                    }
                }
            }
            .MuiBox-root {
                padding-top: 5px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .example
        .rbc-calendar
        .rbc-month-header
        .rbc-header
        .MuiBox-root {
        font-size: 16px;
        line-height: 18px;
        padding: 0 10px;
    }
    .example .rbc-calendar {
        .rbc-time-header-content .rbc-header {
            height: 60px;
            .MuiGrid-item {
                .MuiTypography-body1 {
                    &.font-medium {
                        font-size: 16px;
                        line-height: 22px;
                    }
                    &.font-small {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
            .MuiBox-root {
                padding: 2px 5px 0px !important;
            }
        }
    }
    .MuiInputBase-root.MuiOutlinedInput-root.select_dd {
        width: 40% !important;
    }
    .example .rbc-calendar .calendar_select_wrap {
        justify-content: flex-end !important;
    }
    .example
        .rbc-calendar
        .calendar_select_wrap
        .MuiOutlinedInput-root {
        height: 30px;
        .MuiTypography-body1 {
            font-size: 14px;
        }
    }
    .example .req-time-off-btn.MuiButton-containedPrimary {
        margin-left: 7px;
        padding: 3px 6px;
    }
}

@media only screen and (max-width: 520px) {
    .example {
        .rbc-calendar .mon_year_wrapper {
            margin-right: 0;
        }
        .mon_year_wrapper {
            max-width: 100%;
            flex-basis: 100%;
            margin-right: 0;
        }
    }
}

@media only screen and (max-width: 580px) {
    .example
        .rbc-calendar
        .rbc-month-header
        .rbc-header
        .MuiBox-root {
        font-size: 14px;
        line-height: 16px;
        padding: 0 5px;
    }
}

@media only screen and (max-width: 375px) {
    .example .rbc-calendar {
        .typography18 {
            font-size: 12px;
        }
        .btn-outline {
            height: 30px;
        }
        .mon_year_wrapper {
            .date_wrap {
                font-size: 12px;
                height: 25px;
            }
            button.MuiButtonBase-root.MuiIconButton-root {
                height: 25px;
                width: 25px;
            }
        }
        .calendar_select_wrap {
            .MuiOutlinedInput-root.MuiInputBase-fullWidth
            //     .MuiSelect-outlined.MuiSelect-outlined {
            //     font-size: 12px;
            //     padding: 3px 14px !important;
            // }
            .MuiOutlinedInput-root.MuiInputBase-fullWidth {
                height: 30px;
            }
        }
        .MuiGrid-spacing-xs-2 > .MuiGrid-item {
            padding: 0 8px 4px;
        }
        .currentDay .MuiBox-root {
            border-bottom: 4px solid $primaryDarkBlue;
        }
        .rbc-month-header .rbc-header {
            height: 39px;
            .MuiBox-root {
                font-size: 13px;
            }
        }
    }
}

@media only screen and (max-width: 320px) {
    .example .rbc-calendar {
        .rbc-time-header-content .rbc-header {
            height: 40px;
            .MuiGrid-item {
                .MuiTypography-body1 {
                    &.font-medium {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
            .MuiBox-root {
                padding: 2px 0 0 !important;
            }
        }
    }
}
