.dashboard_Home {
    padding: 40px !important;
}
.dashboard_Home_Struct {
    // display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
}

.leftSide_Dashboard {
    line-height: 40px;
    .teleMed-Logo {
        height: 60px !important;
    }
    .leftTop {
        color: #303e4e !important;
        font-family: Rubik !important;
        font-size: 40px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 60px !important;
        margin-top: 20px;
    }
    .leftMiddle {
        color: #303e4e !important;
        font-family: Rubik !important;
        font-size: 22px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 30px !important;
        // padding-top: 70px !important;
    }
    .assist_text {
        color: #303e4e !important;
        font-family: Rubik !important;
        font-size: 22px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 25px !important;
    }
    .leftBottom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.notificationCard {
    padding: 10px;
    position: relative;
    padding-left: 15px;
    background-color: #fafcfe !important;
    height: 156px !important;
    border-radius: 5px !important;
    border: 1px solid #d8d8d8 !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1) !important;
    .notificationText {
        color: #989ca8 !important;
        text-align: center !important;
        font-family: Rubik !important;
        font-size: 17px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 24px;
    }
}
.appointment_Bill_Card,
.msg_refill_Card {
    // display: flex;
    margin-top: 15px !important;
    .appointment_Card,
    .msg_Card {
        border-radius: 5px !important;
        border: 1px solid #d8d8d8 !important;
        background: #fff;
        height: 190px !important;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1) !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px;
        margin-right: 10px;
        // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 5px !important;
        .card_textHead {
            color: #303e4e !important;
            font-family: Rubik;
            font-size: 20px !important;
            font-style: normal !important;
            font-weight: 500 !important;
            line-height: 140% !important;
            margin-top: 8px !important;
        }
        .card_textBody {
            color: #303e4e !important;
            font-family: Rubik !important;
            font-size: 16px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 140% !important;
        }
        .calender_Logo,
        .msg_Logo {
            border: 1px solid #5571c6;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f5f5f5;
            border-radius: 50px;
            height: 38px;
            width: 38px;
        }
        .calender_Logo img,
        .msg_Logo img {
            height: 28px;
        }
    }
    .bill_Card,
    .refill_Card {
        border-radius: 5px !important;
        border: 1px solid #d8d8d8 !important;
        background: #fff;
        height: 190px !important;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1) !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px;
        margin-left: 20px;
        // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 5px !important;
        .bill_Logo,
        .refill_Logo {
            border: 1px solid #5571c6;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f5f5f5;
            border-radius: 50px;
            height: 38px;
            width: 38px;
        }

        .bill_Logo img,
        .refill_Logo img {
            height: 28px;
        }
    }
    .card_textHead {
        color: #303e4e !important;
        font-family: Rubik;
        font-size: 20px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 140% !important;
        margin-top: 8px !important;
    }
    .card_textBody {
        color: #303e4e !important;
        font-family: Rubik !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 140% !important;
    }
}

.msg_refill_card {
    display: flex;
}

@media (max-width: 600px) {
    .MuiGrid-grid-xs-12 {
        display: flex !important;
        flex-wrap: wrap !important;
        flex-direction: column !important;
    }

    .leftSide_Dashboard {
        line-height: 20px;
        display: flex !important;
        justify-content: space-between !important;
        .leftSide_Text {
            width: 400px;
        }
        .teleMed_Logo {
            height: 50px !important;
        }
        .leftTop {
            font-size: 25px !important;
            line-height: 25px !important;
        }
        .leftMiddle {
            font-size: 18px !important;
            line-height: 25px !important;
        }
        .assist_text {
            color: #303e4e !important;
            font-family: Rubik !important;
            font-size: 22px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 25px !important;
        }
        .leftBottom {
            margin-top: 60px;
        }
    }

    .notificationCard {
        width: 148%;
    }
    .appointment_Bill_Card,
    .msg_refill_Card {
        // display: flex;
        margin-top: 15px !important;
        width: 150%;
        .appointment_Card,
        .msg_Card {
            width: 275%;
        }
        .bill_Card,
        .refill_Card {
            width: 275%;
            margin-left: 0px;
            margin-top: 14px;
        }
    }

    .msg_refill_card {
        display: flex;
    }
}

.member_profile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 148px !important;
}
@media (max-width: 600px) {
    .member_profile {
        height: 120px;
    }
}

.member_profileCard {
    padding: 20px 0px 15px 15px !important;
    // padding-left: 15px;
    // padding-bottom: 15px !important;
    border-radius: 10px !important;
    border: 1px solid #d8d8d8 !important;
    background: #fff !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1) !important;
    width: 80% !important;
    .members_data {
        display: flex;
    }
    .member_Text {
        color: #303e4e !important;
        font-family: Rubik;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal;
    }
    .profile_img {
        display: flex;
        align-items: center;
    }
    .patient_detail {
        display: flex;
        justify-content: space-between;
        margin: 17px;
        margin-left: 20px;
        // align-items: center;
    }
    .patient_data {
        display: flex;
        flex-direction: column;
        line-height: 28px !important;
    }
    #p_Head {
        color: #303e4e !important;
        font-family: Rubik !important;
        font-size: 20px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal !important;
    }
    #p_value {
        color: #303e4e !important;
        font-family: Rubik !important;
        font-size: 17px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
    }
}

@media (max-width: 600px) {
    .member_profileCard {
        width: 80% !important;
        height: 80px !important;
        .members_data {
            display: flex;
        }
        .member_Text {
            color: #303e4e !important;
            font-family: Rubik;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 500 !important;
            line-height: normal;
        }
        .profile_img {
            display: flex;
            align-items: center;
            height: 50px !important;
            width: 50px !important;
            margin-top: 10px;
        }
        .patient_detail {
            display: flex;
            justify-content: space-between;
            margin: 17px;
            margin-left: 20px;
            // align-items: center;
        }
        .patient_data {
            display: flex;
            flex-direction: column;
            line-height: 28px !important;
        }
        #p_Head {
            color: #303e4e !important;
            font-family: Rubik !important;
            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 500 !important;
            line-height: normal !important;
        }
        #p_value {
            color: #303e4e !important;
            font-family: Rubik !important;
            font-size: 10px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }
    }
}
.appointment_Card {
    position: relative;
    transition: border 0.3s ease;
}

.appointment_Card:hover {
    border: 1px solid #5571c6;
}

.calender_Logo img {
    max-width: 100%;
    height: auto;
}

// .card_textHead {
//     font-size: 18px;
//     font-weight: bold;
// }

.refill_Card {
    position: relative;
}

.refill_Card:hover::before {
    content: 'Coming Soon';
    // font-size: 30px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: Rubik;
    background-color: #d9d9d94d;
    color: #303e4e;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 500;
    padding-top: 10px !important;
}

.refill_Card:hover .card_textHead,
.refill_Card:hover .card_textBody,
.refill_Logo:hover {
    filter: blur(2px);
}

.refill_Logo img {
    max-width: 100%;
    height: auto;
}

.card_textHead,
.card_textBody {
    transition: filter 0.3s ease;
}

// .card_textHead {
//     font-size: 18px;
//     font-weight: bold;
// }

.bill_Card {
    position: relative;
}

.bill_Card:hover::before {
    content: 'Coming Soon';
    font-size: 40px;
    font-family: Rubik;
    color: #303e4e;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d9d9d94d;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-size: 25px;
    font-weight: 500;
    padding-top: 10px !important;
}

.bill_Card:hover .card_textHead,
.bill_Card:hover .card_textBody,
.bill_Logo:hover {
    filter: blur(2px);
}

.bill_Logo img {
    max-width: 100%;
    height: auto;
}

.msg_Card {
    position: relative;
}

.msg_Card:hover::before {
    content: 'Coming Soon';
    font-size: 40px;
    font-family: Rubik;
    color: #303e4e;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d9d9d94d;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-size: 25px;
    font-weight: 500;
    padding-top: 10px !important;
}

.notificationCard:hover::before {
    content: 'Coming Soon';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: Rubik;
    background-color: #d9d9d94d;
    color: #303e4e;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 500;
    // padding-top: 10px !important;
}
.notificationCard:hover .notificationText {
    filter: blur(2px);
}

.msg_Card:hover .card_textHead,
.msg_Card:hover .card_textBody,
.refill_Logo:hover {
    filter: blur(2px);
}

.msg_Logo img {
    max-width: 100%;
    height: auto;
}

.go_to_page {
    cursor: pointer;
}
