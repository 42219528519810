@import '../../styles/themes/color.module.scss';
@import '../../styles/themes/sizing.scss';

.sign-up-wrapper {
    max-width: 100% !important;
    padding: 25px 50px !important;
    .astrik {
        color: red;
    }

    .form-wrapper {
        border: 1px solid #cfcfcf;
        border-radius: 5px;
        height: 91vh;
        .right-panel {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .form {
            margin: 2rem 10rem;

            h1 {
                color: #303e4e;
                font-size: 25px;
                font-weight: 400;
            }

            .para-tag {
                color: #667689;
                font-size: 18px;
                font-weight: 500;
            }

            h2 {
                color: #303e4e;
                font-size: 23px;
                font-weight: 400;
            }

            .input-label {
                font-size: 14px;
                padding-bottom: 8px;
            }
            .resend-email-paragraph {
                color: #667689;
                font-size: 19px;
                font-weight: 400;
            }
            .span-text {
                color: #303e4e;
                font-size: 14px;
                font-weight: 400;
            }
            .box-span-container {
                display: flex;
                flex-direction: column;
                gap: 5px;
                margin-bottom: 25px;
                .box-span-text-icon {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }
            }
            .signup-text-input {
                width: 100%;
                color: #777777;
                margin-bottom: 2rem;

                .MuiFormHelperText-root {
                    color: #f44336;
                    margin-right: 0;
                    margin-left: 0;
                }

                .resendotp-grayed {
                    color: #777777;
                    cursor: not-allowed;
                    text-align: right;
                }

                .link-blue {
                    color: $primaryDarkBlue;
                }
            }

            .submit {
                display: flex;
                flex-direction: column;
                align-items: center;

                .btn-primary {
                    font-weight: 700;
                    width: 130px;
                }

                .btn-submit {
                    width: 100% !important;
                    height: 45px !important;
                    border-radius: 5px !important;
                    border: 1px solid rgb(21, 164, 82) !important;
                    background: rgb(21, 164, 82) !important;
                    color: rgb(255, 255, 255) !important;
                    font-size: 16px !important;
                    font-weight: 500 !important;
                    cursor: pointer !important;
                }
                .signup-submit {
                    width: 100% !important;
                    height: 45px !important;
                    border-radius: 5px !important;
                    border: 1px solid rgb(21, 164, 82) !important;
                    background: rgb(21, 164, 82) !important;
                    color: rgb(255, 255, 255) !important;
                    font-size: 16px !important;
                    font-weight: 500 !important;
                    cursor: pointer !important;
                }
                .btn-error {
                    font-weight: 500;
                    color: $primaryWhite !important;
                    background-color: #777777 !important;
                    text-transform: capitalize !important;
                    width: 130px;
                    cursor: not-allowed;
                }

                .btn-resend {
                    width: 70% !important;
                    height: 45px !important;
                    border-radius: 5px !important;
                    border: 1px solid #e9ecf1 !important;
                    background: #f5f5f5 !important;
                    color: #303e4e !important;
                    font-size: 16px !important;
                    font-weight: 500 !important;
                    cursor: pointer !important;
                }

                h5 {
                    color: #777777;
                    margin-top: 15px;
                    font-weight: normal;
                    font-size: 12px;

                    a {
                        color: $primaryDarkBlue;
                        margin-left: $space-5;
                        font-weight: 700;
                        font-size: 12px;
                    }
                }
            }

            .error-login {
                color: #777777;
                text-align: center;
                margin-top: 94px;
                font-weight: normal;
                font-size: 12px;

                a {
                    font-weight: 700;
                    font-size: 12px;
                }
            }

            .date-picker {
                width: 100%;

                // .MuiOutlinedInput-input {
                //     padding: 10.5px 14px;
                // }
                &.MuiFormControl-marginNormal {
                    margin-top: 0;
                    margin-bottom: 2rem !important;
                }
            }

            .form-gender {
                margin-bottom: 1rem;
            }
        }
        .form-footer {
            margin: 2rem 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .footer-tag {
                font-size: 18px;
                color: #667689;
                font-weight: 500;
                a {
                    font-size: 18px;
                    color: #5571c6;
                    font-weight: 500;
                }
            }
        }
        .logo-wrapper {
            margin: 2rem 3rem;
            .form-logo {
                width: 117px;
                height: 52px;
            }
        }
    }
}

.MuiFormHelperText-root.MuiFormHelperText-contained {
    margin-left: 0;
    margin-right: 0;
}

@media only screen and (max-width: 768px) {
    .container .form-wrapper .form h1 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 582px) {
    .MuiContainer-root {
        // background: $success;
        // height: calc(100vh - 80px);
        form {
            background: $primaryWhite;
        }
    }

    .container .form-wrapper .form h1 {
        font-size: 16px;
        margin-bottom: 25px;
    }
}

.signup-text-input .MuiOutlinedInput-root {
    color: #516983 !important;
}
