@import '../../../styles/themes/color.module.scss';
.tabs-wrapper {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display: inline-flex;
    position: relative;
    width: 100%;
    &__tab {
        float: left;
        position: relative;
        display: block;
        margin-right: 4px;
        border: 4px solid transparent;
        border-radius: 4px 4px 0 0;
        padding: 10px 15px;
        min-width: 80px;
        text-align: center;
        font-size: 15px;
        color: $primaryDarkBlue;
        background: #eff2fa;
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        &.active {
            cursor: default;
            background-color: $primaryWhite;
            border: 1px solid transparent;
            border-bottom-color: $primaryDarkBlue;
            border-bottom-width: 4px;
            font-weight: bold;
        }
    }
}
