// Common sizing values for theming consistency
$auto: auto;
$none: 0;
$xs: 8px;
$sm: 12px;
$nm: 16px;
$md: 20px;
$lg: 24px;
$xl: 28px;

$space-5: 5px;
$space-10: 10px;
$space-15: 15px;
$space-20: 20px;
$space-25: 25px;
$space-30: 30px;

// Breakpoints for Media Query

$mobile-small: 320px;
$mobile-medium: 360px;
$mobile-large: 582px;
$tablet: 768px;
$tablet-pro: 1024px;
$desktop-small: 1180px;
$desktop-medium: 1280px;
$desktop-large: 2200px;

:export {
    auto: $auto;
    none: $none;
    xs: $xs;
    sm: $sm;
    nm: $nm;
    md: $md;
    lg: $lg;
    xl: $xl;
    space-5: $space-5;
    space-10: $space-10;
    space-15: $space-15;
    space-20: $space-20;
    space-25: $space-25;
    space-30: $space-30;
    mobile-small: $mobile-small;
    mobile-medium: $mobile-medium;
    mobile-large: $mobile-large;
    tablet: $tablet;
    tablet-pro: $tablet-pro;
    desktop-small: $desktop-small;
    desktop-medium: $desktop-medium;
    desktop-large: $desktop-large;
}
